import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { toast } from "react-toastify";
import Select from "react-select";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import debounce from "lodash.debounce";
import chunk from "lodash.chunk";
import { read, utils } from "xlsx";

import Loader from "../../common/Loader";
import Button from "../../common/Button";
import FormInput from "../../common/FormInput";
import Stepper from "../../common/Stepper";
import _ from "../../../i18n";
import {
  getUserByEmail,
  getUsersByEmail,
  saveImportCollaborators,
} from "../../../api";
import { SELECT_STYLES } from "../../../config";
import {
  validateEmail,
  validatePhone,
  getRemainAddCollaborator,
} from "../../../utils";
import styles from "./CollaboratorModal.module.scss";

const dataFields = [
  {
    label: _("firstname"),
    key: "firstName",
    required: true,
    type: "text",
    synonyms: ["name", "first name", "prenom", "prénom", "voornaam"],
  },
  {
    label: _("lastname"),
    key: "lastName",
    required: true,
    type: "text",
    synonyms: ["last name", "family name", "second name", "nom", "achternaam"],
  },
  {
    label: _("email"),
    key: "email",
    required: true,
    type: "email",
    synonyms: [
      "email",
      "e-mail",
      "e mail",
      "mail",
      "e-mail address",
      "email address",
      "email addr",
      "email inbox",
      "inbox",
      "adresse mail",
      "adresse email",
      "adr email",
    ],
  },
  {
    label: _("phone_number"),
    key: "phone",
    required: false,
    type: "phone",
    synonyms: [
      "phone",
      "phone number",
      "mobile",
      "mobile number",
      "telephone",
      "phone num",
      "num",
      "téléphone",
      "telefoon",
    ],
  },
  {
    label: _("language"),
    key: "language",
    required: true,
    type: "enum",
    values: ["fr", "nl", "en"],
    selectOptions: [
      { value: "fr", label: _("french") },
      { value: "nl", label: _("dutch") },
      { value: "en", label: _("english") },
    ],
    hasDefault: true,
    defaultValue: { value: "fr", label: "fr" },
    synonyms: ["language", "lng", "langue", "taal"],
    mapping: [
      { key: "fr", values: ["français", "french", "frans", "fr"] },
      { key: "en", values: ["anglais", "english", "engels", "en"] },
      { key: "nl", values: ["néerlandais", "dutch", "nederlands", "nl"] },
    ],
  },
];

const mergeByOptions = [
  { value: "space", key: " ", label: _("space") },
  { value: "comma", key: ", ", label: _("comma") + " ( , )" },
  { value: "semicolon", key: "; ", label: _("semicolon") + " ( ; )" },
  { value: "dash", key: " - ", label: _("dash") + " ( - )" },
  { value: "underscore", key: "_", label: _("underscore") + " ( _ )" },
];

const CollaboratorModal = ({
  modalOpen,
  setModalOpen,
  responseData,
  setResponseData,
  afterSave,
  nbCollaborators,
  noLimit,
}) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [rowsData, setRowsData] = useState("");
  const [isImportStep, setIsImportStep] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [headOptions, setHeadOptions] = useState([]);
  const [initialHeadOptions, setInitialHeadOptions] = useState([]);
  const [headValues, setHeadValues] = useState([]);
  const [headUsedKeys, setHeadUsedKeys] = useState({});
  const [isHeaderError, setIsHeaderError] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [step, setStep] = useState(1); // 4 steps
  const [headerIndex, setHeaderIndex] = useState(0);
  const [dropDownId, setDropDownId] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [fieldsModalOpen, setFieldsModalOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [tmpField, setTmpField] = useState({
    fieldIndex: null,
    columns: [],
    action: "",
    result: [],
    isValid: false,
    mergeBy: mergeByOptions[0],
  });
  const [defaultValues, setDefaultValues] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [mergeValues, setMergeValues] = useState([]);
  const [previewMergeValues, setPreviewMergeValues] = useState([]);
  const [remainAddCount, setRemainAddCount] = useState(1);

  useEffect(() => {
    if (fiduciary) {
      setRemainAddCount(
        getRemainAddCollaborator(fiduciary.officeSize, nbCollaborators)
      );
    }
  }, [fiduciary]);

  useEffect(() => {
    if (responseData) {
      // handleResponseData(responseData);
      setInitialData(JSON.parse(JSON.stringify(responseData)));
      setIsImportStep(true);
    }
  }, [responseData]);

  useEffect(() => {
    setPreviewData(processData());
  }, [previewMergeValues]);

  const handleSave = () => {
    if (rowsData) {
      const workbook = read(rowsData, { type: "string" });
      const ws = workbook.Sheets[workbook.SheetNames[0]];
      const data = utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
        defval: "",
      });
      setResponseData(data);
    }
  };

  const toggleDropDown = (e, itemIndex) => {
    e.stopPropagation();
    dropDownId === itemIndex ? setDropDownId(null) : setDropDownId(itemIndex);
  };

  const validateMultiSave = () => {
    let error = false;
    bodyData.forEach((item) => {
      item.forEach((cell) => {
        if (cell.error) {
          error = true;
        }
      });
    });
    return error;
  };

  const handleResponseData = (data, isReturn = false) => {
    let idIndex = null;
    data.head.forEach((item, index) => {
      if (item === "id") {
        idIndex = index;
      }
    });
    const tab = [];
    data.body.forEach((row, rowIndex) => {
      tab[rowIndex] = [];
      row.forEach((cell, cellIndex) => {
        if (cellIndex < idIndex || !idIndex) {
          if (typeof cell !== "object" || cell === null) {
            let value = cell;
            if (dataFields[cellIndex]?.type === "enum") {
              let option = dataFields[cellIndex].selectOptions.filter(
                (i) => i.value.toLocaleLowerCase() === value.toLowerCase()
              );
              if (option && option.length > 0) {
                value = option[0];
              }
            }
            tab[rowIndex][cellIndex] = {
              error: false,
              value,
            };
          } else {
            tab[rowIndex][cellIndex] = {
              error: true,
              value: cell.value,
              errorLabel: cell.error,
            };
          }
        }
      });
    });
    if (isReturn) {
      return tab;
    } else {
      setBodyData(tab);
    }
  };

  const handleMultiSave = async () => {
    if (!noLimit && bodyData.length > remainAddCount) {
      toast.error(
        _("collaborator_count_reached").replace("%count%", remainAddCount)
      );
      return null;
    }

    let data = {
      head: dataFields.map((item) => item.key),
      body: [],
    };
    // data.head.push("id");
    // data.head.push("emailId");
    // data.head.push("phoneId");
    // data.head.push("role");

    let tabData = bodyData.map((row) => {
      let tab = [];
      tab = row.map((cell) => {
        return cell.value !== null && typeof cell.value === "object"
          ? cell.value.value
          : cell.value;
      });
      // tab.push(row[0].id ? row[0].id : "");
      // tab.push(row[0].emailId ? row[0].emailId : "");
      // tab.push(row[0].phoneId ? row[0].phoneId : "");
      // tab.push(row[0].role ? row[0].role : "");

      return tab;
    });
    const error = validateMultiSave();
    if (error || isHeaderError) {
      toast.error(_("validate_data"));
      return null;
    }

    setIsSaving(true);
    const chunked = chunk(tabData, 25);
    let returnData = [];
    let hasError = false;
    let importFileCount = 0;
    let importedWithError = false;
    for (let i = 0; i < chunked.length; i++) {
      setProgressValue(Math.floor((i / chunked.length) * 100));
      const d = new Date();
      let time = d.getTime();
      var file = new File(
        [
          JSON.stringify({ ...data, body: chunked[i] }, (key, value) =>
            typeof value === "string" ? encodeURIComponent(value) : value
          ),
        ],
        `data-${time}.txt`,
        {
          type: "text/plain",
        }
      );

      try {
        const resp = await saveImportCollaborators(
          auth.token,
          file,
          fiduciary.id
        );
        importFileCount++;
      } catch (error) {
        if (error.response) {
          if (error.response?.data?.data) {
            const tab = handleResponseData(error.response.data.data, true);
            if (tab.length > 0) {
              returnData.push(...tab);
            }

            if (
              error.response.data.newCollab &&
              error.response.data.newCollab.length > 0
            ) {
              importedWithError = true;
            }
          } else {
            hasError = true;
          }
        } else {
          hasError = true;
          // toast.error(_("error_try_again"));
        }
      }
    }

    if (returnData.length > 0) {
      setBodyData(returnData);
      if (importedWithError) {
        toast.info(_("users_imported_with_errors"));
      } else {
        toast.error(_("validate_data"));
      }
      setProgressValue(0);
    } else if (hasError) {
      if (importFileCount < chunked.length) {
        toast.info(_("users_imported_with_errors"));
      } else {
        toast.error(_("error_try_again"));
      }
      setProgressValue(0);
    } else {
      afterSave();
      setTimeout(() => {
        handleCancel();
        setModalOpen(false);
        toast.success(_("successfully_added"));
      }, 1000);
    }
    setIsSaving(false);
  };

  const handleCancel = () => {
    if (responseData) {
      setModalOpen(false);
    }
    setStep(1);
    setIsImportStep(false);
    setBodyData([]);
    setIsHeaderError(false);
  };

  const cancelDefaultValueHeader = (e, index) => {
    let tab = defaultValues.filter((i, idx) => idx !== index);
    setDefaultValues(tab);
  };

  const handleChangeHeader = (e, index) => {
    let tab = [...headValues];
    tab[index] = e;
    setHeadValues(tab);
  };

  const handleMergeHeader = (action, index) => {
    if (action === "SPLIT") {
      setTmpField({
        ...tmpField,
        action,
        columns: null,
        fieldIndex: index,
      });
    } else if (action === "MERGE") {
      setTmpField({ ...tmpField, action, fieldIndex: index });
    } else if (action === "DEFAULT") {
      setTmpField({
        ...tmpField,
        action,
        columns: null,
        selectOptions: dataFields[index].selectOptions,
        fieldIndex: index,
      });
    }
    setFieldsModalOpen(true);
  };

  const handleRemoveRow = (index) => {
    let tab = bodyData.filter((item, idx) => idx !== index);
    setBodyData(tab);
  };

  const handleAcceptExistUser = (rowIndex, cellIndex) => {
    let tab = bodyData.map((item, idx) => {
      if (idx === rowIndex) {
        item = item.map((cell, key) => {
          if (key === cellIndex) {
            cell.error = false;
            setDropDownId(null);
          }
          return cell;
        });
      }
      return item;
    });
    setBodyData(tab);
  };

  const debouncedLoadResults = debounce((email, rowIndex, cellIndex) => {
    if (email.length > 0 && validateEmail(email)) {
      // setIsFetchingData(true);
      getUserByEmail(auth.token, email)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            let tab = bodyData.map((item, idx) => {
              if (idx === rowIndex) {
                item = item.map((cell, key) => {
                  if (key === cellIndex) {
                    let emailItem = resp.data.data[0].email.filter(
                      (i) => i.email === email
                    )[0];

                    item[0].id = resp.data.data[0].id;
                    item[0].emailId = emailItem.id;

                    if (
                      resp.data.data[0].phone &&
                      resp.data.data[0].phone.length > 0
                    ) {
                      item[0].phoneId = resp.data.data[0].phone[0].id;
                    }

                    if (
                      resp.data.data[0].roles.length > 0 &&
                      !resp.data.data[0].role
                    ) {
                      item[cellIndex].error = true;
                      item[cellIndex].errorLabel = _(
                        "text_add_exist_collaborator"
                      );
                      item[0].exist = true;
                      item[0].role = true;
                    }
                  }
                  return cell;
                });
              }
              return item;
            });
            setBodyData(tab);
          }
          setIsFetchingData(false);
        })
        .catch((e) => {
          let tab = bodyData.map((item, idx) => {
            if (idx === rowIndex) {
              item = item.map((cell, key) => {
                if (key === cellIndex) {
                  item[0].id = null;
                  item[0].emailId = null;
                  item[0].phoneId = null;
                  item[0].exist = false;
                  item[0].role = false;
                }
                return cell;
              });
            }
            return item;
          });
          setBodyData(tab);
          setIsFetchingData(false);
        });
    }
  }, 1000);

  const handleSelectCell = (value, rowIndex, cellIndex) => {
    let tab = bodyData.map((item, idx) => {
      if (idx === rowIndex) {
        item = item.map((cell, key) => {
          if (key === cellIndex) {
            cell.value = value;
            handleErrorCell(cell, dataFields[cellIndex]);
          }
          return cell;
        });
      }
      return item;
    });
    setBodyData(tab);
  };

  const handleInputCell = (value, rowIndex, cellIndex) => {
    let tab = bodyData.map((item, idx) => {
      if (idx === rowIndex) {
        item = item.map((cell, key) => {
          if (key === cellIndex) {
            cell.value = value;
            handleErrorCell(cell, dataFields[cellIndex]);
          }
          return cell;
        });
      }
      return item;
    });
    setBodyData(tab);
  };

  const handleErrorCell = (cell, options) => {
    if (cell.value === undefined || cell.value === null || cell.value === "") {
      if (options.required) {
        cell.error = true;
        cell.errorLabel = "Required value";
      } else {
        cell.error = false;
      }
    } else {
      if (options.type === "text") {
        cell.error = false;
      } else if (options.type === "email") {
        if (!validateEmail(cell.value)) {
          cell.error = true;
          cell.errorLabel = _("validate_email");
        } else {
          cell.error = false;
        }
      } else if (options.type === "phone") {
        const resultValid = validatePhone(cell.value);
        if (!resultValid.valid) {
          cell.error = true;
          cell.errorLabel = _("validate_phone");
        } else {
          cell.error = false;
        }
      } else if (options.type === "enum") {
        if (!options.values.includes(cell.value.value)) {
          cell.error = true;
          cell.errorLabel = _("invalid_value");
        } else {
          cell.error = false;
        }
      }
    }
  };

  const handleCheckboxClick = (e, index) => {
    let tabResult = [...tmpField.result];
    let isValid = false;
    tabResult.forEach((item, idx) => {
      tabResult[idx].checked = index === idx ? !item.checked : false;
      if (tabResult[idx].checked) {
        isValid = true;
      }
    });
    setTmpField({ ...tmpField, result: tabResult, isValid });
  };

  const handleChangeMergeBy = (e) => {
    let columns = [],
      result = [];
    tmpField.columns.forEach((column) => {
      columns.push(column.label);
    });
    result.push({
      value: columns.join("_"),
      label: columns.filter((str) => str).join(e.key),
      index: 0,
    });

    setTmpField({ ...tmpField, mergeBy: e, result });
  };

  const handleFieldsSave = () => {
    let initHeaderCountFields = initialData[headerIndex].length;
    mergeValues.forEach((i) => {
      if (i.action === "SPLIT") {
        initHeaderCountFields += 2;
      } else if (i.action === "MERGE") {
        initHeaderCountFields += 1;
      }
    });

    let tab = [...mergeValues, { ...tmpField }];
    setMergeValues(tab);
    setPreviewMergeValues(tab);

    let tmpHeadValues = [...headValues];
    let tabFields = [],
      result = [];

    switch (tmpField.action) {
      case "SPLIT":
        result = tmpField.result.map((i, idx) => {
          if (i.checked) {
            tmpHeadValues[tmpField.fieldIndex] = {
              ...i,
              index: initHeaderCountFields + idx,
            };
          }
          return { ...i, index: initHeaderCountFields + idx };
        });

        tabFields = headOptions.filter((i) => tmpField.fieldIndex !== i.index);
        let tmpHeadOptions = [...tabFields, ...result];
        setHeadOptions(tmpHeadOptions);

        tmpHeadValues = tmpHeadValues.map((i) => {
          if (i && i.value === tmpField.columns.value) {
            return null;
          }
          return i;
        });
        break;

      case "MERGE":
        result = tmpField.result.map((i, idx) => {
          tmpHeadValues[tmpField.fieldIndex] = {
            ...i,
            index: initHeaderCountFields,
          };

          return { ...i, index: initHeaderCountFields };
        });

        const mergeKeys = tmpField.columns.map((col) => {
          return col.value;
        });
        tabFields = headOptions.filter((i) => !mergeKeys.includes(i.value));
        let tmpHeadOptions2 = [...tabFields, ...result];
        setHeadOptions(tmpHeadOptions2);

        tmpHeadValues = tmpHeadValues.map((i) => {
          if (i && mergeKeys.includes(i.value)) {
            return null;
          }
          return i;
        });
        break;

      case "DEFAULT":
        let tmp = [...defaultValues];
        tmp[tmpField.fieldIndex] = tmpField.columns;
        setDefaultValues(tmp);
        tmpHeadValues[tmpField.fieldIndex] = null;
        break;
    }
    setHeadValues(tmpHeadValues);
    closeFieldsModal();
  };

  const handleChangeColumn = (e) => {
    let isValid = false;
    if (e) {
      let result = [];
      switch (tmpField.action) {
        case "SPLIT":
          result.push({
            value: initialHeadOptions[e.index].value + "_1",
            label: initialHeadOptions[e.index].label + "_1",
            checked: false,
            // index: initHeaderCountFields,
          });
          result.push({
            value: initialHeadOptions[e.index].value + "_2",
            label: initialHeadOptions[e.index].label + "_2",
            checked: false,
            // index: initHeaderCountFields + 1,
          });
          break;

        case "MERGE":
          let columns = [];
          e.forEach((column) => {
            columns.push(column.label);
          });
          result.push({
            value: columns.join("_"),
            label: columns.filter((str) => str).join(tmpField.mergeBy.key),
            // index: initHeaderCountFields,
          });
          isValid = columns.length > 1;
          break;

        case "DEFAULT":
          isValid = true;
          break;
      }
      setTmpField({ ...tmpField, columns: e, result, isValid });
    } else {
      tmpField.result = [];
      setTmpField({ ...tmpField, columns: [], result: [], isValid });
    }
  };

  const showStep2 = () => {
    const values = [];
    const headKeys = {};
    setMergeValues([]);
    setPreviewMergeValues([]);
    setDefaultValues([]);
    const head = responseData[headerIndex].map((item, index) => {
      values[index] = { value: item, label: item, index };
      headKeys[item] = false;
      return { value: item, label: item, index, isDisabled: headKeys[item] };
    });
    setFieldOptions(head);
    setHeadOptions(head);
    setInitialHeadOptions(head);

    const headValues = dataFields.map((item, index) => {
      let result = null;
      responseData[headerIndex].forEach((column, idx) => {
        if (item.synonyms.includes(column.toLowerCase())) {
          result = { value: column, label: column, index: idx };
        }
      });
      return result;
    });
    setHeadUsedKeys(headKeys);
    setHeadValues(headValues);

    setPreviewData(processData());

    setStep(2);
  };

  const processData = () => {
    let data = JSON.parse(JSON.stringify(initialData));
    data = data.filter((row, index) => index !== headerIndex);

    // check merge or split fields
    if (mergeValues.length > 0 && mergeValues[0].columns) {
      mergeValues.forEach((item, i) => {
        switch (item.action) {
          case "SPLIT":
            data.forEach((row, index) => {
              let originValue = row[item.columns.index].trim().split(" ");
              if (originValue.length > 1) {
                data[index].push(originValue.slice(0, 1).join(" "));
                data[index].push(originValue.slice(1).join(" "));
              }
            });
            break;
          case "MERGE":
            data.forEach((row, index) => {
              let values = [];
              item.columns.forEach((cell) => {
                values.push(row[cell.index]);
              });
              data[index].push(
                values.filter((str) => str).join(item.mergeBy.key)
              );
            });
            break;
        }
      });
    }

    // clean empty lines
    data = data.filter((row, rowIndex) => {
      let countEmpty = 0;
      row.forEach((cell, cellIndex) => {
        let value = cell;
        if (typeof value === "string") {
          value = value.trim();
        }
        if (!value) {
          countEmpty++;
        }
      });
      return countEmpty < row.length;
    });

    return data;
  };

  const showStep3 = () => {
    const headData = {};
    headValues.forEach((column, index) => {
      headData[index] = column && column?.index >= 0 ? column.index : null;
    });

    const data = processData();

    const tab = [];
    const emails = [];
    const emailsKeys = {};
    data.forEach((row, index) => {
      tab[index] = [];
      dataFields.forEach((column, idx) => {
        if (
          column.type === "email" &&
          headData[idx] !== null &&
          validateEmail(row[headData[idx]])
        ) {
          emails.push(row[headData[idx]]);
          emailsKeys[row[headData[idx]]] = {
            rowIndex: index,
            columnIndex: idx,
          };
        }
        if (headData[idx] !== null) {
          let value = row[headData[idx]];

          if (column.type === "enum") {
            let option = [];
            if (column.key === "language") {
              const mappingOption = column.mapping.filter((i) =>
                i.values.includes(value.toLowerCase())
              );
              if (mappingOption.length > 0) {
                option = column.selectOptions.filter(
                  (i) => i.value === mappingOption[0].key
                );
              }
            } else {
              option = column.selectOptions.filter(
                (i) => i.value.toLocaleLowerCase() === value.toLowerCase()
              );
            }
            if (option.length > 0) {
              value = option[0];
            }
          }
          tab[index][idx] = {
            value: value,
            id: null,
            emailId: null,
            phoneId: null,
            role: null,
          };
        } else {
          let value = null;
          if (defaultValues[idx]) {
            value = defaultValues[idx];
          }
          tab[index][idx] = {
            value,
            id: null,
            emailId: null,
            phoneId: null,
            role: null,
          };
        }
        handleErrorCell(tab[index][idx], column);
      });
    });
    setBodyData(tab);

    /*if (emails.length > 0) {
      const chunked = chunk(emails, 10);
      setIsFetchingData(true);
      for (let i = 0; i < chunked.length; i++) {
        try {
          const resp = await getUsersByEmail(auth.token, chunked[i]);
          if (resp.data.data.length > 0) {
            resp.data.data.forEach((item, idx) => {
              item.email.forEach((emailItem) => {
                if (emailsKeys[emailItem.email]) {
                  tab[emailsKeys[emailItem.email].rowIndex][0].id = item.id;
                  tab[emailsKeys[emailItem.email].rowIndex][0].emailId =
                    emailItem.id;
                  if (item.phone && item.phone.length > 0) {
                    tab[emailsKeys[emailItem.email].rowIndex][0].phoneId =
                      item.phone[0].id;
                  }
                  if (item.roles.length > 0 && !item.role) {
                    tab[emailsKeys[emailItem.email].rowIndex][
                      emailsKeys[emailItem.email].columnIndex
                    ].error = true;
                    tab[emailsKeys[emailItem.email].rowIndex][
                      emailsKeys[emailItem.email].columnIndex
                    ].errorLabel = _("text_add_exist_collaborator");
                    tab[emailsKeys[emailItem.email].rowIndex][
                      emailsKeys[emailItem.email].columnIndex
                    ].exist = true;
                    tab[emailsKeys[emailItem.email].rowIndex][0].role = true;
                  }
                }
              });
            });
          }
        } catch (e) {}
      }
      setBodyData(tab);
      setIsFetchingData(false);
    }*/
    setStep(3);
  };

  const closeFieldsModal = () => {
    setDropDownId(null);
    setFieldsModalOpen(false);
    setTmpField({
      fieldIndex: null,
      columns: [],
      action: "",
      result: [],
      isValid: false,
      mergeBy: mergeByOptions[0],
    });
  };

  const renderStep1 = () => {
    if (responseData.length === 0) {
      return null;
    }

    // const columnsCount = initialData[0].length;

    return (
      <>
        <div className={styles.modal_body}>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <tbody>
                {responseData.map((row, index) => {
                  return (
                    <tr
                      key={`row${index}`}
                      className={classNames(
                        styles.pointer,
                        headerIndex === index && styles.rowHeader
                      )}
                      onClick={() => setHeaderIndex(index)}
                    >
                      <td align="center">{index + 1}</td>
                      <td>
                        <input
                          type="radio"
                          value={index}
                          checked={headerIndex === index}
                          onChange={() => setHeaderIndex(index)}
                        />
                      </td>
                      {row.map((item, idx) => (
                        <td key={`td-${idx}`}>{item}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.actions}>
          <Button variant="default" onClick={handleCancel}>
            {_("cancel")}
          </Button>
          <Button onClick={showStep2}>{_("continue")}</Button>
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <div className={styles.modal_body}>
          <h3 className={styles.title}>{_("map_columns")}</h3>
          <table className={styles.table}>
            <thead>
              <tr className={styles.rowHeader}>
                <th width="25%" className={styles.mapping}>
                  {_("our_fields")}
                </th>
                <th width="35%" className={styles.mapping}>
                  {_("columns_in_file")}
                </th>
                <th width="40%">{_("preview")}</th>
              </tr>
            </thead>
            <tbody>
              {dataFields.map((item, idx) => {
                return (
                  <tr key={`col-${idx}`}>
                    <td className={styles.mapping}>{item.label}</td>
                    <td className={styles.mapping}>
                      <div className={styles.mapping_flex}>
                        {defaultValues[idx] ? (
                          <Select
                            styles={SELECT_STYLES}
                            // options={headOptions}
                            isClearable={true}
                            value={defaultValues[idx]}
                            onChange={(e) => cancelDefaultValueHeader(e, idx)}
                          />
                        ) : (
                          <Select
                            styles={SELECT_STYLES}
                            options={headOptions}
                            isSearchable={false}
                            isClearable={true}
                            value={headValues[idx]}
                            onChange={(e) => handleChangeHeader(e, idx)}
                          />
                        )}
                        <span
                          className={`${styles.mappingtooltip} ${
                            dropDownId === "mapping_" + idx && styles.active
                          }`}
                        >
                          <i
                            className={`${
                              dropDownId === "mapping_" + idx
                                ? "icon-ttp-close"
                                : "icon-ttp-three-dots"
                            } ${styles.settingsIcon}`}
                            onClick={(e) => toggleDropDown(e, "mapping_" + idx)}
                            title="click"
                          />
                        </span>
                        <ul
                          className={`${styles.mappingDropdown} ${
                            dropDownId === "mapping_" + idx && styles.show
                          }`}
                        >
                          <li onClick={() => handleMergeHeader("SPLIT", idx)}>
                            {_("split")}
                          </li>
                          <li onClick={() => handleMergeHeader("MERGE", idx)}>
                            {_("merge")}
                          </li>
                          {item.hasDefault && (
                            <li
                              onClick={() => handleMergeHeader("DEFAULT", idx)}
                            >
                              {_("fill_with_default_values")}
                            </li>
                          )}
                        </ul>
                      </div>
                    </td>
                    <td>
                      <ul className={styles.preview}>
                        {previewData.map((preview, previewIndex) => {
                          if (previewIndex > 4) {
                            return null;
                          }
                          let value = headValues[idx]
                            ? preview[headValues[idx].index]
                            : null;
                          return (
                            <li key={`prev-${previewIndex}-${idx}`}>{value}</li>
                          );
                        })}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles.actions}>
          <Button variant="default" onClick={handleCancel}>
            {_("cancel")}
          </Button>
          <div className="d-flex">
            <Button onClick={() => setStep(1)} variant="default">
              {_("back")}
            </Button>
            <Button onClick={showStep3}>{_("continue")}</Button>
          </div>
        </div>
      </>
    );
  };

  const renderStep3 = () => {
    return (
      <>
        <div className={styles.modal_body}>
          <h3 className={styles.title}>Verify data</h3>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.rowHeader}>
                  <th></th>
                  <th>#</th>
                  {dataFields.map((item, idx) => (
                    <th key={`th${idx}`}>{item.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bodyData.map((item, index) => {
                  let hasError = false;
                  item.forEach((cell) => {
                    if (cell.error) {
                      hasError = true;
                    }
                  });
                  return (
                    <tr
                      key={`row${index}`}
                      className={hasError ? styles.rowError : ""}
                    >
                      <td
                        align="center"
                        className={styles.remove}
                        onClick={() => handleRemoveRow(index)}
                        title={_("remove")}
                      >
                        <i className="icon-ttp-trash"></i>
                      </td>
                      <td align="center">{index + 1}</td>
                      {item.map((cell, idx) => {
                        return (
                          <td
                            key={`td${idx}`}
                            className={cell.error ? styles.error : ""}
                          >
                            {dataFields[idx].type === "enum" ? (
                              <Select
                                options={dataFields[idx].selectOptions}
                                value={cell.value}
                                styles={SELECT_STYLES}
                                onChange={(e) =>
                                  handleSelectCell(e, index, idx)
                                }
                              />
                            ) : (
                              <input
                                type="text"
                                className="ttp-cell-input"
                                autocomplete="off"
                                value={cell.value}
                                onChange={(e) =>
                                  handleInputCell(e.target.value, index, idx)
                                }
                              />
                            )}
                            {cell.error && cell.exist ? (
                              <>
                                <span
                                  className={`${styles.tooltip} ${
                                    dropDownId === index + "_" + idx &&
                                    styles.active
                                  }`}
                                >
                                  <i
                                    className={`${
                                      dropDownId === index + "_" + idx
                                        ? "icon-ttp-close"
                                        : "icon-ttp-question-circle-o"
                                    }`}
                                    onClick={(e) =>
                                      toggleDropDown(e, index + "_" + idx)
                                    }
                                    title="click"
                                  />
                                </span>
                                <div
                                  className={`${styles.menuDropdown} ${
                                    dropDownId === index + "_" + idx &&
                                    styles.show
                                  }`}
                                >
                                  {_("text_popover_add_exist_collaborator")}

                                  <div className={styles.menuDropdown_actions}>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      {_("delete")}
                                    </Button>
                                    <Button
                                      size="sm"
                                      onClick={() =>
                                        handleAcceptExistUser(index, idx)
                                      }
                                    >
                                      {_("validate")}
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              cell.error && (
                                <Tippy
                                  className={styles.tippy}
                                  content={<span>{cell.errorLabel}</span>}
                                >
                                  <span className={styles.tooltip}>
                                    <i className="icon-ttp-alert-circle"></i>
                                  </span>
                                </Tippy>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.actions}>
          <Button variant="default" onClick={handleCancel}>
            {_("cancel")}
          </Button>
          <div className="d-flex">
            {isSaving && (
              <div className={styles.progress}>
                <div>{progressValue}%</div>
                <div className={styles.progress_bar}>
                  <span
                    className={styles.progress_value}
                    style={{ width: progressValue + "%" }}
                  ></span>
                </div>
              </div>
            )}
            <Button onClick={() => setStep(2)} variant="default">
              {_("back")}
            </Button>
            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={handleMultiSave}>{_("save")}</Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        width="95vw"
        height="50vh"
        closable={false}
        visible={modalOpen}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          setModalOpen(false);
          handleCancel();
        }}
        maskClosable={false}
        destroyOnClose={true}
        keyboard={false}
      >
        <div className={styles.modal_header}>
          {fiduciary.uaMode === "UNIVERSITY"
            ? _("import_students")
            : _("import_collaborators")}
        </div>
        <div
          className={styles.modal_close}
          onClick={(e) => {
            e.stopPropagation();
            setModalOpen(false);
            handleCancel();
          }}
        >
          <i className="icon-ttp-close"></i>
        </div>

        {!isImportStep ? (
          <>
            <div className={styles.modal_body}>
              <label className={styles.labelTitle}>Copier une table</label>
              <p className={styles.labelTitle}>
                Merci de faire attention que l'entête des colonnes soit présente
              </p>
              <FormInput
                name="email"
                type="textarea"
                autocomplete="off"
                value={rowsData}
                rows="15"
                onChange={(e) => setRowsData(e.target.value)}
              />
            </div>
            <div className={styles.actions}>
              <Button variant="default" onClick={() => setModalOpen(false)}>
                {_("cancel")}
              </Button>
              {isSaving ? (
                <Button
                  variant="primary"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                >
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#fff"}
                  />
                </Button>
              ) : (
                <Button onClick={handleSave}>{_("continue")}</Button>
              )}
            </div>
          </>
        ) : (
          <div
            className={classNames(
              styles.modalContent,
              isFetchingData ? styles.loading : ""
            )}
          >
            <Stepper
              steps={[
                _("select_header"),
                _("map_fields"),
                _("verify_finalize"),
              ]}
              currentStep={step}
            />
            {remainAddCount > 0 && (
              <div className={styles.countBar}>
                {_("collaborator_count_reached").replace(
                  "%count%",
                  remainAddCount
                )}
              </div>
            )}

            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}
          </div>
        )}
      </Modal>
      <Modal
        width="50vw"
        height="50vh"
        closable={false}
        visible={fieldsModalOpen}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          closeFieldsModal();
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.modal_header}>{_("merge_modal_title")}</div>
        <div
          className={styles.modal_close}
          onClick={(e) => {
            e.stopPropagation();
            closeFieldsModal();
          }}
        >
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.modal_body}>
          {tmpField.action === "SPLIT" && (
            <>
              <div className="ttp-form-group">
                <label className={styles.title}>Select field to split</label>
                <Select
                  styles={SELECT_STYLES}
                  options={fieldOptions}
                  isSearchable={false}
                  isClearable={true}
                  value={tmpField.columns}
                  onChange={(e) => handleChangeColumn(e)}
                />
              </div>
              {tmpField.result.length > 0 && (
                <p>
                  <label className={styles.title}>Select field </label>
                  <ul>
                    {tmpField.result.map((i, idx) => (
                      <li key={`result-${idx}`} className={styles.splitField}>
                        <label>
                          <input
                            name="splitField"
                            type="checkbox"
                            checked={i.checked}
                            onChange={(e) => handleCheckboxClick(e, idx)}
                          />{" "}
                          {i.label}
                        </label>
                      </li>
                    ))}
                  </ul>
                </p>
              )}
            </>
          )}{" "}
          {tmpField.action === "MERGE" && (
            <>
              <div className="ttp-form-group">
                <label className={styles.title}>Select fields to merge</label>
                <Select
                  styles={SELECT_STYLES}
                  options={fieldOptions}
                  isSearchable={false}
                  isClearable={true}
                  value={tmpField.columns}
                  isMulti={true}
                  onChange={(e) => handleChangeColumn(e)}
                />
              </div>
              <div className="ttp-form-group">
                <label className={styles.title}>Merge by</label>
                <Select
                  styles={SELECT_STYLES}
                  options={mergeByOptions}
                  isSearchable={false}
                  isClearable={true}
                  value={tmpField.mergeBy}
                  onChange={(e) => handleChangeMergeBy(e)}
                />
              </div>
              {tmpField.result.length > 0 && (
                <p>
                  Result: <label>{tmpField.result[0].label}</label>
                </p>
              )}
            </>
          )}
          {tmpField.action === "DEFAULT" && (
            <>
              <label className={styles.title}>Select default value</label>
              <Select
                styles={SELECT_STYLES}
                options={tmpField.selectOptions}
                isSearchable={false}
                isClearable={true}
                value={tmpField.columns}
                onChange={(e) => handleChangeColumn(e)}
              />
            </>
          )}
        </div>
        <div className={styles.actions}>
          <Button
            variant="default"
            onClick={() => {
              closeFieldsModal();
            }}
          >
            {_("cancel")}
          </Button>
          <Button
            onClick={() => handleFieldsSave()}
            disabled={!tmpField.isValid}
          >
            {_("save")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CollaboratorModal;

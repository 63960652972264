import React from "react";
import { useSelector } from "react-redux";
import _ from "../../../../../../../../i18n";
import styles from "./About.module.scss";

const About = ({ data, image }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.about_title}>{_("about")}</div>
        <div className={styles.title}>
          {data[titleAttr]
            ? data[titleAttr]
            : "Cursus eget nunc scelerisque viverra mauris"}
        </div>
        <div className={styles.description}>
          {data[descriptionAttr]
            ? data[descriptionAttr]
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim facilisis gravida neque convallis a cras semper auctor neque."}
        </div>
      </div>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${
            image ? image.fullMediaUrl : "img/no_image.png"
          })`,
        }}
      ></div>
    </div>
  );
};

export default About;

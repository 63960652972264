import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-loading-skeleton/dist/skeleton.css";

import Switch from "../../common/Switch/Switch";
import DisabledSwitch from "../../common/Switch/Switch/DisabledSwitch";
import Button from "../../common/Button";
import Loader from "../../common/Loader";

import { setFiduciary } from "../../../store";
import { saveAppsPreferences } from "../../../api";
import _ from "../../../i18n";

// import styles from "./WatchSharing.module.scss";
import styles from "./InstalledApp.module.scss";

const BlogConfig = ({ showModal, closeModal }) => {
  const dispatch = useDispatch();
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);

  const [isSaving, setIsSaving] = useState(false);
  const [source, setSource] = useState([]);

  useEffect(() => {
    if (showModal) {
      if (fiduciary?.blogPreferences?.source) {
        setSource(
          fiduciary.blogPreferences.source.filter((i) => i !== "COLLEAGUE")
        );
      }
    }
  }, [showModal]);

  const handleSave = () => {
    let data = {
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        appName: "blog",
        source,
        lRId: fiduciary.legalRepresentative.id,
      },
    };
    setIsSaving(true);
    saveAppsPreferences(data)
      .then((resp) => {
        setIsSaving(false);
        toast.success(_("successfully_saved"));
        const preferences = fiduciary.blogPreferences || {};
        dispatch(
          setFiduciary({
            ...fiduciary,
            blogPreferences: {
              ...preferences,
              source,
            },
          })
        );
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error"));
      });
  };

  const handleChange = (isChecked, value) => {
    let tmp = [...source];
    if (isChecked) {
      tmp.push(value);
    } else {
      tmp = tmp.filter((i) => i !== value);
    }
    setSource(tmp);
  };

  return (
    <div>
      <h3>Configurez les sources d'articles</h3>

      <ul className={styles.blog_box}>
        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Ma bibliothèque</h3>
            {fiduciary.uaMode === "OECCBB_MEMBER" ? (
              <DisabledSwitch isChecked={false} />
            ) : (
              <Switch
                isChecked={source.includes("MY_LIBRARY")}
                onChange={(e) => handleChange(e, "MY_LIBRARY")}
              />
            )}
          </div>
          <p>{_("app_blog_tools_1_desc")}</p>
        </li>

        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Activer la bibliothèque FFF</h3>
            <Switch
              isChecked={source.includes("FFF_LIBRARY")}
              onChange={(e) => handleChange(e, "FFF_LIBRARY")}
            />
          </div>
          <p>
            Afficher les articles de Forum for the future. Vous aurez la
            possibilité de partager vos articles avec la bibliothèque FFF
          </p>
        </li>

        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Partager avec les confrères</h3>

            <DisabledSwitch isChecked={false} />
          </div>
          <p>Mes articles seront partager avec mes confrères</p>
        </li>
      </ul>

      <div className={styles.actions2}>
        <Button variant="default" onClick={() => closeModal()}>
          {_("close")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </div>
  );
};

export default BlogConfig;

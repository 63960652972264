import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-modal";
import { ModalConfirm } from "tamtam-components";

import { writeFile, utils } from "xlsx";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import classnames from "classnames";

import FormInput from "../../../../common/FormInput";
import Button from "../../../../common/Button";
import Loader from "../../../../common/Loader";
import Checkbox from "../../../../common/Checkbox";
import {
  getHotels,
  getMembersByHotel,
  saveHotel,
  getGroupByName,
  addContactToGroup,
  removeContactFromGroup,
} from "../../../../../api";
import { SELECT_STYLES, GOOGLE_MAP_API_KEY } from "../../../../../config";
import _ from "../../../../../i18n";
import styles from "./OeccbbHotels.module.scss";
import MapView from "./MapView";
import { IconTrash } from "../../../../common/icons/IconTrash";

const distanceOptions = [
  { value: 5, label: "5 km" },
  { value: 10, label: "10 km" },
  { value: 15, label: "15 km" },
  { value: 20, label: "20 km" },
  { value: 25, label: "25 km" },
  { value: 30, label: "30 km" },
  { value: 35, label: "35 km" },
  { value: 40, label: "40 km" },
  { value: 45, label: "45 km" },
  { value: 50, label: "50 km" },
];

const limitOptions = [
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 },
  { value: 200, label: 200 },
  { value: 250, label: 250 },
  { value: 300, label: 300 },
];

const currentYear =
  moment().month() == 11 ? moment().year() + 1 : moment().year();

const yearsOptions = [{ value: currentYear, label: currentYear }];
const notMembersYearsOptions = [
  { value: currentYear, label: currentYear },
  { value: currentYear - 1, label: currentYear - 1 },
];

for (let i = currentYear - 1; i >= 2020; i--) {
  yearsOptions.push({ value: i, label: i });
}

const OeccbbHotels = () => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const [hotelOptions, setHotelOptions] = useState([]);
  const [hotel, setHotel] = useState(null);
  const [distance, setDistance] = useState(distanceOptions[3]);
  const [limit, setLimit] = useState(limitOptions[1]);
  const [year, setYear] = useState([yearsOptions[0]]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllNoAddress, setSelectAllNoAddress] = useState(false);
  const [selectedNoAddressIds, setSelectedNoAddressIds] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [viewMode, setViewMode] = useState("LIST"); // LIST | MAP
  const [infoBoxes, setInfoBoxes] = useState([]);
  const [showMoreLimit, setShowMoreLimit] = useState(false);
  const [members, setMembers] = useState([]);
  const [notMemberFilter, setNotMemberFilter] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({ id: null, name: null });
  const [groupSearchResults, setGroupSearchResults] = useState([]);
  const [saveGroup, setSaveGroup] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [savingGroup, setSavingGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const currentYear = moment().format("YYYY");
  const { token } = auth;
  const [errors, setErrors] = useState({
    name: "",
    address: "",
    zipcode: "",
    city: "",
  });

  let { isLoading, data } = useQuery(["getHotels", auth.token], async () => {
    if (auth.token) {
      const response = await getHotels({ token: auth.token });

      const hotels = response.data.data.map((hotel) => {
        return {
          value: hotel.id,
          label: hotel.name,
          address: hotel.address + " - " + hotel.zipCode + " - " + hotel.city,
          latitude: hotel.latitude,
          longitude: hotel.longitude,
        };
      });
      setHotelOptions(hotels);

      return response.data.data;
    }
  });

  let dataMembers = useQuery(
    [
      "getMembers",
      auth.token,
      hotel,
      distance,
      limit,
      showMoreLimit,
      year,
      notMemberFilter,
    ],
    async () => {
      if (auth.token && hotel) {
        const tab = [
          {
            location: [parseFloat(hotel.latitude), parseFloat(hotel.longitude)],
            addHandler: "mouseover",
            infoboxOption: {
              title: hotel.label,
            },
            pushPinOption: {
              color: "#18A0FB",
              icon: "https://unpkg.com/leaflet@1.3.3/dist/images/marker-icon.png",
            },
          },
        ];

        try {
          const years = year.map((i) => i.value);
          const response = await getMembersByHotel({
            token: auth.token,
            hotel: hotel.value,
            distance: distance.value,
            limit: limit.value,
            showMoreLimit,
            years: years.join(","),
            notMembersYears: notMemberFilter,
          });

          if (response.data.data.result) {
            response.data.data.result.forEach((item, index) => {
              tab.push({
                id: item.user.id,
                zIndex: index,
                position: {
                  lat: parseFloat(item.latitude),
                  lng: parseFloat(item.longitude),
                },
                infoboxOption: {
                  title: item.user.firstName + " " + item.user.lastName,
                  description: item.user.mainEmail || "",
                  distance: item.distance,
                },
              });
            });
            setInfoBoxes(tab);
          }

          setMembers(response.data.data.result);

          return response.data.data;
        } catch (e) {
          setInfoBoxes(tab);
          setMembers([]);
          return [];
        }
      }
    }
  );

  const selectMember = (item, type = "WITH_ADDRESS") => {
    if (type === "WITH_ADDRESS") {
      if (selectedIds.includes(item.user.id)) {
        const updatedSelectedIds = selectedIds.filter(
          (element) => element !== item.user.id
        );
        setSelectedIds(updatedSelectedIds);
        const updatedGroups = [];
        updatedSelectedIds.forEach((userId) => {
          const user = members.find((member) => member.user.id === userId);
          if (user && user.user.groups) {
            user.user.groups.forEach((group) => {
              if (!updatedGroups.map((g) => g.id).includes(group.id)) {
                updatedGroups.push(group);
              }
            });
          }
        });
        setGroups(updatedGroups);
      } else {
        setSelectedIds([...selectedIds, item.user.id]);
        if (item.user.groups) {
          const updatedGroups = [...groups];
          item.user.groups.forEach((group) => {
            if (!updatedGroups.map((g) => g.id).includes(group.id)) {
              updatedGroups.push(group);
            }
          });
          setGroups(updatedGroups);
        }
      }
    } else {
      if (selectedNoAddressIds.includes(item.user.id)) {
        const updatedSelectedNoAddressIds = selectedNoAddressIds.filter(
          (element) => element !== item.user.id
        );
        setSelectedNoAddressIds(updatedSelectedNoAddressIds);

        const updatedGroups = [];
        updatedSelectedNoAddressIds.forEach((userId) => {
          const user = members.find((member) => member.user.id === userId);
          if (user && user.user.groups) {
            user.user.groups.forEach((group) => {
              if (!updatedGroups.map((g) => g.id).includes(group.id)) {
                updatedGroups.push(group);
              }
            });
          }
        });
        setGroups(updatedGroups);
      } else {
        setSelectedNoAddressIds([...selectedNoAddressIds, item.user.id]);

        if (item.user.groups) {
          const updatedGroups = [...groups];
          item.user.groups.forEach((group) => {
            if (!updatedGroups.map((g) => g.id).includes(group.id)) {
              updatedGroups.push(group);
            }
          });
          setGroups(updatedGroups);
        }
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
      setGroups([]);
    } else {
      if (dataMembers?.data?.result) {
        const allMemberIds = dataMembers.data.result.map(
          (item) => item.user.id
        );
        setSelectedIds([...allMemberIds]);
        const allGroups = [];
        dataMembers.data.result.forEach((item) => {
          if (item.user.groups) {
            item.user.groups.forEach((group) => {
              if (!allGroups.map((g) => g.id).includes(group.id)) {
                allGroups.push(group);
              }
            });
          }
        });
        setGroups(allGroups);
      }
    }
    setSelectAll(!selectAll);
  };

  const reset = () => {
    setGroupSearchResults([]);
    setSelectedGroup({ id: null, name: null });
    setSaveGroup(false);
    setDeleteGroup(false);
    setSelectedIds([]);
    setSelectAll(false);
    setFetching(false);
    setSearchValue("");
    setShowCreateGroup(false);
  };
  const handleSaveGroup = async () => {
    setSavingGroup(true);
    await addContactToGroup(
      token,
      {
        group: selectedGroup,
        contacts: selectedIds,
      },
      fiduciary.id
    );
    queryClient.invalidateQueries("getMembers");
    setSavingGroup(false);
    queryClient.invalidateQueries("listGroups");
    reset();
  };
  const handleSelectAllNoAddress = () => {
    if (selectAllNoAddress) {
      setSelectedNoAddressIds([]);
    } else {
      if (dataMembers?.data?.other) {
        setSelectedNoAddressIds([
          ...selectedNoAddressIds,
          ...dataMembers.data.other.map((item) => item.user.id),
        ]);
      }
    }
    setSelectAllNoAddress(!selectAllNoAddress);
  };

  const handleCopy = async () => {
    if (
      (selectedIds.length === 0 && selectedNoAddressIds.length === 0) ||
      !dataMembers.data
    ) {
      return null;
    }
    let data = [...dataMembers.data?.result];
    if (dataMembers.data?.other) {
      data = [...data, ...dataMembers.data.other];
    }
    let ids = [...selectedIds, ...selectedNoAddressIds];

    const emails = [];

    data.forEach((item) => {
      if (ids.includes(item.user.id)) {
        emails.push(item.user.mainEmail);
      }
    });

    try {
      await navigator.clipboard?.writeText(emails.join(","));
      toast.success("Données copiées");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const handleExport = () => {
    if (
      (selectedIds.length === 0 && selectedNoAddressIds.length === 0) ||
      !dataMembers.data
    ) {
      return null;
    }

    const rows = [];
    const columnsWidth = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    let data = [...dataMembers.data?.result];
    if (dataMembers.data?.other) {
      data = [...data, ...dataMembers.data.other];
    }
    let ids = [...selectedIds, ...selectedNoAddressIds];

    data.forEach((item) => {
      if (ids.includes(item.user.id)) {
        const name = `${item.user.lastName ? item.user.lastName : ""} ${
          item.user.firstName ? item.user.firstName : ""
        }`;
        if (name.length > columnsWidth[0].wch) {
          columnsWidth[0].wch = name.length;
        }
        const row = {
          "Nom / Prénom": name,
          Email: item.user.mainEmail ?? "",
          Téléphone: item.user.mainPhone
            ? formatPhoneNumberIntl(item.user.mainPhone)
            : "",
          ["Adhésion " + currentYear]: item.hasPurchased ? "Oui" : "Non",
          Distance: item.distance ? item.distance : "",
          Adhésions: item.purchasedYears ? item.purchasedYears.join(", ") : "",
        };
        rows.push(row);

        if (row.Email.length > columnsWidth[1].wch) {
          columnsWidth[1].wch = row.Email.length;
        }
        if (row["Téléphone"].length > columnsWidth[2].wch) {
          columnsWidth[2].wch = row["Téléphone"].length;
        }
        if (row["Adhésion " + currentYear].length > columnsWidth[3].wch) {
          columnsWidth[3].wch = row["Adhésion " + currentYear].length;
        }
        if (row.Distance.length > columnsWidth[4].wch) {
          columnsWidth[4].wch = row.Distance.length;
        }
        if (row.Adhésions.length > columnsWidth[5].wch) {
          columnsWidth[5].wch = row.Adhésions.length;
        }
      }
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Dates");

    /* fix headers */
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Nom / Prénom",
          "Email",
          "groupes",
          "Téléphone",
          "Adhésion " + currentYear,
          "Distance",
          "Adhésions",
        ],
      ],
      { origin: "A1" }
    );

    /* calculate column width */
    const max_width = rows.reduce(
      (w, r) => Math.max(w, r["Nom / Prénom"].length),
      10
    );
    worksheet["!cols"] = columnsWidth;

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFile(workbook, "Contacts - " + hotel.label + ".xlsx");
  };

  const handleCancel = () => {
    setHotelName("");
    setAddress("");
    setZipcode("");
    setCity("");
    setLatitude("");
    setLongitude("");
    setErrors({ name: "", address: "", zipcode: "", city: "" });
    setAddModalOpen(false);
  };

  const validate = () => {
    let tabErrors = { ...errors };
    tabErrors.name = hotelName.length === 0 ? _("required_field") : "";
    tabErrors.address = address.length === 0 ? _("required_field") : "";
    tabErrors.zipcode = zipcode.length === 0 ? _("required_field") : "";
    tabErrors.city = city.length === 0 ? _("required_field") : "";

    setErrors(tabErrors);

    return tabErrors.name ||
      tabErrors.address ||
      tabErrors.zipcode ||
      tabErrors.city
      ? true
      : false;
  };

  const handleSave = async () => {
    let error = validate();
    if (error) {
      return null;
    }

    let lat = latitude,
      lng = longitude;
    setIsSaving(true);
    // if (!latitude || !longitude) {
    //   try {
    //     const addressStr = encodeURIComponent(
    //       hotelName + " " + address + " " + zipcode + " " + city + " Belgique"
    //     );
    //     let response = await fetch(
    //       "https://maps.google.com/maps/api/geocode/json?address=" +
    //         addressStr +
    //         "&sensor=false&key=" +
    //         GOOGLE_MAP_API_KEY
    //     );
    //     let responseJson = await response.json();
    //     if (responseJson.status == "OK") {
    //       lat = responseJson.results[0].geometry.location.lat;
    //       lng = responseJson.results[0].geometry.location.lng;
    //       setLatitude(lat);
    //       setLongitude(lng);
    //     }
    //   } catch (error) {
    //     console.log(error);

    //     toast.error(
    //       "Veuillez vérifier l'adresse, nous n'avons pas pu la geocoder"
    //     );
    //     setIsSaving(false);
    //     return null;
    //   }
    // }

    const data = {
      token: auth.token,
      name: hotelName,
      address,
      zipcode,
      city,
      latitude: lat,
      longitude: lng,
    };

    saveHotel(data)
      .then((resp) => {
        queryClient.invalidateQueries("getHotels");
        setTimeout(() => {
          toast.success(_("successfully_added"));
          handleCancel();
        }, 1000);
      })
      .catch((e) => {})
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleHotelNameChange = (name) => {
    if (name.length === 0) {
      setErrors({ ...errors, name: _("required_field") });
    } else {
      setErrors({ ...errors, name: "" });
    }
    setHotelName(name);
  };

  const handleSelectHotelName = (address, placeId, suggestion) => {
    if (suggestion && suggestion.formattedSuggestion) {
      setHotelName(suggestion.formattedSuggestion.mainText);
    }

    geocodeByAddress(address)
      .then((results) => results[0])
      .then(async (results) => {
        const addressComponents = results.address_components;

        const zip_code = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const comp_city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        const state = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("administrative_area_level_1")
        );
        const country = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("country")
        );
        const city = comp_city.length > 0 ? comp_city[0].long_name : "";
        const zipCode = zip_code.length > 0 ? zip_code[0].long_name : "";
        let cleanAdd = results.formatted_address;
        if (zipCode.length > 0) cleanAdd = cleanAdd.replace(", " + zipCode, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(city, "");
        if (country.length > 0)
          cleanAdd = cleanAdd.replace(", " + country[0].long_name, "");
        if (state.length > 0)
          cleanAdd = cleanAdd.replace(", " + state[0].long_name, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(", " + city, "");

        try {
          const geometry = await getLatLng(results);
          setAddress(cleanAdd);
          setZipcode(zipCode);
          setCity(city);
          setLatitude(geometry.lat);
          setLongitude(geometry.lng);

          setErrors({
            ...errors,
            zipCode: zipCode ? "" : _("required_field"),
            city: city ? "" : _("required_field"),
          });
        } catch (e) {}

        // setHotelName()
      })
      .catch((error) => console.error("Error", error));
  };
  const handleGroupSearch = async (name) => {
    setShowCreateGroup(false);
    if (name && name.length > 0) {
      setFetching(true);
      try {
        const res = await getGroupByName(token, name, fiduciary.id);
        if (res) {
          setGroupSearchResults(res.data.data);
        }
        setFetching(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setGroupSearchResults([]);
          setFetching(false);
          setShowCreateGroup(true);
        }
      }
    } else {
      setGroupSearchResults([]);
      setFetching(false);
    }
  };
  const handleDeleteGroup = async () => {
    setDeletingGroup(true);
    await removeContactFromGroup(token, {
      group: selectedGroup,
      contacts: selectedIds,
    });
    queryClient.invalidateQueries("getMembers");
    setDeletingGroup(false);
    queryClient.invalidateQueries("listGroups");
    reset();
  };
  return (
    <div className="grid-container">
      {(isLoading || dataMembers.isLoading) && <div className="lmask"></div>}
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span>Liste des hôtels :</span>

          <div className={styles.top_actions}>
            <Button
              onClick={handleCopy}
              variant="default"
              size="sm"
              disabled={
                selectedIds.length === 0 && selectedNoAddressIds.length == 0
              }
            >
              <i className="icon-ttp-copy" /> Copier
            </Button>
            <Button
              onClick={handleExport}
              variant="default"
              size="sm"
              disabled={
                selectedIds.length === 0 && selectedNoAddressIds.length == 0
              }
            >
              <i className="icon-ttp-download" /> Exporter
            </Button>
            <Button
              onClick={() => setAddModalOpen(true)}
              variant="primary"
              size="sm"
            >
              <i className="icon-ttp-plus-outline" /> Ajouter un hôtel
            </Button>
          </div>
        </h1>

        {!isLoading && data && (
          <>
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-12 large-6">
                <div className="">
                  <label className="ttp-label">Hôtel</label>
                  <Select
                    styles={SELECT_STYLES}
                    isSearchable={false}
                    options={hotelOptions}
                    getOptionLabel={(options) => (
                      <div>
                        <span>{options.label}</span>
                        <br />
                        <small>{options.address}</small>
                      </div>
                    )}
                    value={hotel}
                    onChange={(e) => {
                      setShowMoreLimit(false);
                      setHotel(e);
                    }}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 large-3">
                <div className="">
                  <label className="ttp-label">Distance</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={distanceOptions}
                    isSearchable={false}
                    value={distance}
                    onChange={(e) => {
                      setShowMoreLimit(false);
                      setDistance(e);
                    }}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 large-3">
                <div className="">
                  <label className="ttp-label">Limit</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={limitOptions}
                    isSearchable={false}
                    value={limit}
                    onChange={(e) => {
                      setShowMoreLimit(false);
                      setLimit(e);
                    }}
                  />
                </div>
              </div>

              <div className="cell small-12 medium-6 large-6">
                <div className="">
                  <label className="ttp-label">Année</label>
                  <Select
                    isMulti
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={year}
                    onChange={(e) => {
                      setShowMoreLimit(false);
                      setYear(e);
                    }}
                    // selectStyles={selectStyles}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 large-6">
                <div className={styles.filterCheckbox}>
                  {notMembersYearsOptions.map((year) => (
                    <div
                      key={`nmember-${year.value}`}
                      className={styles.filterCheckbox_item}
                    >
                      <Checkbox
                        checked={notMemberFilter.includes(year.value)}
                        onClick={(e) => {
                          if (notMemberFilter.includes(year.value)) {
                            setNotMemberFilter(
                              notMemberFilter.filter((i) => i !== year.value)
                            );
                          } else {
                            setNotMemberFilter([
                              ...notMemberFilter,
                              year.value,
                            ]);
                          }
                        }}
                      />
                      Non membre {year.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              {selectedIds.length > 0 && (
                <>
                  {groups.length > 0 && (
                    <div className={styles.groups}>
                      {groups.map((item) => (
                        <span className={styles.groupName}>
                          {item.name}
                          <div
                            onClick={() => {
                              setSelectedGroup({
                                id: item.id,
                                name: item.name,
                              });
                              setDeleteGroup(true);
                            }}
                          >
                            <IconTrash size={14} />
                          </div>
                        </span>
                      ))}
                    </div>
                  )}
                  <div className={styles.inputGroup}>
                    <input
                      type="text"
                      placeholder="Attribution de groupe à la sélection"
                      onChange={(e) => {
                        handleGroupSearch(e.target.value);
                        setSearchValue(e.target.value);
                      }}
                      // onKeyDown={(e) => handleKeyPress(e)}
                      value={searchValue}
                    />
                    {fetching ? (
                      <ul className={styles.searchResults}>
                        <li className={styles.center}>
                          <Loader
                            style={{
                              height: "10px",
                            }}
                            color={"#B2BCC6"}
                          />
                        </li>
                      </ul>
                    ) : groupSearchResults.length > 0 ? (
                      <ul className={styles.searchResults}>
                        {groupSearchResults.map((item) => (
                          <li
                            key={item.id}
                            onClick={() => {
                              setSelectedGroup({
                                id: item.id,
                                name: item.name,
                              });
                              setSaveGroup(true);
                            }}
                          >
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    ) : groupSearchResults.length === 0 && showCreateGroup ? (
                      <ul className={styles.searchResults}>
                        <li
                          onClick={() => {
                            setSelectedGroup({
                              ...selectedGroup,
                              name: searchValue,
                            });
                            setSaveGroup(true);
                          }}
                        >
                          {_("create_group")}
                        </li>
                      </ul>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <hr className={styles.sperator} />

            <div className={styles.topBar}>
              <h4>
                Nombre de résultats:{" "}
                {dataMembers.data && (
                  <span>
                    ({dataMembers.data.result.length}) / séléctionnés: (
                    {selectedIds.length})
                  </span>
                )}
                {/* {dataMembers.isLoading && viewMode === "MAP" && (
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                )} */}
              </h4>

              <div className={styles.top_actions}>
                <Button
                  onClick={() => setViewMode("LIST")}
                  variant="default"
                  size="sm"
                >
                  <i className="icon-ttp-filter" /> Liste
                </Button>
                <Button
                  onClick={() => setViewMode("MAP")}
                  variant="default"
                  size="sm"
                >
                  <i className="icon-ttp-earth" /> Carte
                </Button>
              </div>
            </div>

            {viewMode === "LIST" ? (
              <div className={styles.tableContainer}>
                <table className={`${styles.table} unstriped`}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onClick={() => handleSelectAll()}
                        />
                      </th>
                      <th width="30%" className={styles.nowrap}>
                        {_("lastname") + " / " + _("firstname")}
                      </th>
                      <th width="40%">{_("groups")}</th>
                      <th width="40%">Email</th>
                      <th width="25%">Téléphone</th>
                      <th>Adhésion {currentYear}</th>
                      <th width="25%">Distance</th>
                      <th>Adhésions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.length > 0 ? (
                      <>
                        {members.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(item.user.id)}
                                onClick={() => selectMember(item)}
                              />
                            </td>
                            <td>
                              {`${
                                item.user.lastName ? item.user.lastName : ""
                              } ${
                                item.user.firstName ? item.user.firstName : ""
                              }`}
                            </td>
                            <td>
                              {item.user.groups &&
                                item.user.groups.map((element, i) => (
                                  <span
                                    key={i}
                                    className={styles.groupName}
                                    style={{
                                      marginBottom:
                                        i === item.user.groups.length - 1
                                          ? "0"
                                          : "",
                                    }}
                                  >
                                    {element.name}
                                  </span>
                                ))}
                            </td>
                            <td>{item.user.mainEmail}</td>
                            <td className={styles.nowrap}>
                              {item.user.mainPhone
                                ? formatPhoneNumberIntl(item.user.mainPhone)
                                : "-"}
                            </td>
                            <td>{item.hasPurchased ? "Oui" : "Non"}</td>
                            <td>{item.distance}</td>
                            <td>
                              {item.purchasedYears
                                ? item.purchasedYears.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6} className={styles.centerCell}>
                          Liste vide (aucun contact trouvé)
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!dataMembers.isLoading &&
                  !showMoreLimit &&
                  dataMembers?.data?.result?.length < limit.value && (
                    <p
                      onClick={() => setShowMoreLimit(true)}
                      className={styles.loadMore}
                    >
                      Charger plus
                    </p>
                  )}
              </div>
            ) : (
              <div className={styles.mapbox}>
                {/* <ReactBingmaps
                  bingmapKey="AlfqRT9EH4KtKEzZQp4yhxAIWxiujabG1jpSuUJaQ_GWxN9ONI_MQvd_e2kJpK5H"
                  zoom={13}
                  mapTypeId={"aerial"}
                  mapOptions={{ disableScrollWheelZoom: true }}
                  center={[
                    parseFloat(hotel.latitude),
                    parseFloat(hotel.longitude),
                  ]}
                  navigationBarMode={"compact"}
                  infoboxesWithPushPins={infoBoxes}
                /> */}
                <MapView hotel={hotel} markers={infoBoxes} />
              </div>
            )}

            {!dataMembers.isLoading &&
              dataMembers.data &&
              dataMembers.data?.other?.length > 0 && (
                <>
                  <h4 style={{ marginTop: "1rem" }}>
                    Liste des membres sans adresse: (
                    {dataMembers.data.other.length})
                  </h4>
                  <div className={styles.tableContainer}>
                    <table className={`${styles.table} unstriped`}>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAllNoAddress}
                              onClick={() => handleSelectAllNoAddress()}
                            />
                          </th>
                          <th width="30%" className={styles.nowrap}>
                            {_("lastname") + " / " + _("firstname")}
                          </th>
                          <th width="40%">Email</th>
                          <th width="25%">Téléphone</th>
                          <th width="25%">Distance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataMembers.data.other.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedNoAddressIds.includes(
                                  item.user.id
                                )}
                                onClick={() =>
                                  selectMember(item, "WITHOUT_ADDRESS")
                                }
                              />
                            </td>
                            <td>
                              {`${
                                item.user.lastName ? item.user.lastName : ""
                              } ${
                                item.user.firstName ? item.user.firstName : ""
                              }`}
                            </td>
                            <td>{item.user.mainEmail}</td>
                            <td className={styles.nowrap}>
                              {item.user.mainPhone
                                ? formatPhoneNumberIntl(item.user.mainPhone)
                                : "-"}
                            </td>
                            <td>{item.distance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
          </>
        )}
      </div>
      <ModalConfirm
        type="send"
        isOpen={saveGroup}
        onCancel={() => {
          setSelectedGroup({ id: null, name: null });
          setSaveGroup(false);
        }}
        onConfirm={() => handleSaveGroup()}
        inProcess={savingGroup}
        title={_("save_confirm")}
        text={_("text_save_group")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type="delete"
        isOpen={deleteGroup}
        onCancel={() => {
          setSelectedGroup({ id: null, name: null });
          setDeleteGroup(false);
        }}
        onConfirm={() => handleDeleteGroup()}
        inProcess={deletingGroup}
        title={_("delete_confirm")}
        text={_("text_delete_group")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <Modal
        isOpen={addModalOpen}
        // onRequestClose={() => resetData()}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Ajout d'un hôtel</div>
          <div className={styles.close} onClick={() => setAddModalOpen(false)}>
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.body}>
            <label className="ttp-label">
              Nom de l'hôtel
              <span className="star">*</span>
            </label>
            <PlacesAutocomplete
              value={hotelName}
              onChange={handleHotelNameChange}
              onSelect={handleSelectHotelName}
              searchOptions={{
                componentRestrictions: { country: ["be"] },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="group-input">
                  <input
                    {...getInputProps({
                      // placeholder: "Search Places ...",
                      className: classnames(
                        "location-search-input",
                        errors.address1 ? "error" : ""
                      ),
                    })}
                    autoComplete="none"
                    name="name"
                  />
                  <span className="ttp-error">{errors.name}</span>
                  {suggestions.length > 0 ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>

            <FormInput
              name="address"
              required={true}
              label="Adresse"
              autocomplete="off"
              error={errors.address}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <FormInput
                  name="zipcode"
                  required={true}
                  label="Code postal"
                  autocomplete="off"
                  error={errors.zipcode}
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-6">
                <FormInput
                  name="city"
                  required={true}
                  label="Ville"
                  autocomplete="off"
                  error={errors.city}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <FormInput
                  name="latitude"
                  label="Latitude"
                  autocomplete="off"
                  value={latitude}
                  disabled
                />
              </div>

              <div className="cell small-12 medium-6">
                <FormInput
                  name="longitude"
                  label="Longitude"
                  autocomplete="off"
                  value={longitude}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <Button variant="default" onClick={handleCancel}>
              {_("cancel")}
            </Button>

            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={handleSave}>{_("save")}</Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OeccbbHotels;

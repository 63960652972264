import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import { getFiduciaries, saveFiduciaryStatus } from "../../api";
import _ from "../../i18n";
import { SELECT_STYLES } from "../../config";
import { formatPhone } from "../../utils";

import Pagination from "../common/Pagination";
import Loader from "../common/Loader";
import Button from "../common/Button";

import CommunityModal from "./CommunityModal";
import FiduciaryModal from "./FiduciaryModal";
import FiduciaryAcceptModal from "./FiduciaryAcceptModal";
import NoteModal from "./NoteModal";
import HistoryModal from "./HistoryModal";
import styles from "./ManageFiduciary.module.scss";

const statusOptions = [
  { label: _("pending"), value: "PENDING" },
  { label: _("validated"), value: "VALIDATED" },
  { label: _("rejected"), value: "REJECTED" },
  { label: _("deleted"), value: "DELETED" },
];

const ManageFiduciary = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [status, setStatus] = useState(statusOptions[0]);
  const [dropDownId, setDropDownId] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showFiduciaryModal, setShowFiduciaryModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showCommunityModal, setShowCommunityModal] = useState(false);
  const LIMIT = 10;

  let { isLoading, data } = useQuery(
    ["listFiduciaries", token, searchName, sort, status, currentPage],
    async () => {
      if (token) {
        try {
          const response = await getFiduciaries({
            token,
            status: status.value,
            page: currentPage,
            limit: LIMIT,
            sort,
            name: searchName,
          });

          return response.data;
        } catch (e) {
          return [];
        }
      }
    }
  );

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setCurrentPage(1);
    setSearchName(search);
  }, 1000);

  const toggleDropDown = (e, id) => {
    e.stopPropagation();
    dropDownId === id ? setDropDownId(null) : setDropDownId(id);
  };

  const getActions = (item) => {
    return (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          {status.value === "PENDING" && (
            <>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowValidateModal(true);
                }}
              >
                {_("validate")}
              </li>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowRejectModal(true);
                }}
              >
                {_("reject")}
              </li>
            </>
          )}

          {status.value === "VALIDATED" && (
            <>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowPendingModal(true);
                }}
              >
                {_("put_on_pending")}
              </li>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowRejectModal(true);
                }}
              >
                {_("reject")}
              </li>
            </>
          )}

          {status.value === "REJECTED" && (
            <>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowPendingModal(true);
                }}
              >
                {_("put_on_pending")}
              </li>
              <li
                onClick={(e) => {
                  toggleDropDown(e, item.id);
                  setCurrentItem(item);
                  setShowValidateModal(true);
                }}
              >
                {_("validate")}
              </li>
            </>
          )}

          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              setCurrentItem(item);
              setShowDeleteModal(true);
            }}
          >
            {_("delete")}
          </li>
          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              setCurrentItem(item);
              setShowFiduciaryModal(true);
            }}
          >
            {_("edit")}
          </li>
          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              setCurrentItem(item);
              setShowNoteModal(true);
            }}
          >
            {_("add_note")}
          </li>
        </ul>
      </div>
    );
  };

  const handleValidateAction = () => {
    setIsSaving(true);
    saveFiduciaryStatus(token, { id: currentItem.id, uaStatus: "VALIDATED" })
      .then((resp) => {
        toast.success(_("ua_status_successfully"));
        queryClient.invalidateQueries("listFiduciaries");
        setIsSaving(false);
        setShowValidateModal(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handlePutOnPendingAction = () => {
    let dataNote = currentItem.note ? currentItem.note : [];
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    dataNote.push({
      date: strDate,
      msg: "STATUS_PENDING_AFTER_VALIDATED",
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    setIsSaving(true);
    saveFiduciaryStatus(token, {
      id: currentItem.id,
      uaStatus: "PENDING",
      note: JSON.stringify(dataNote),
    })
      .then((resp) => {
        toast.success(_("ua_status_successfully"));
        queryClient.invalidateQueries("listFiduciaries");
        setIsSaving(false);
        setShowPendingModal(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleRejectAction = () => {
    let dataNote = currentItem.note ? currentItem.note : [];
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    dataNote.push({
      date: strDate,
      msg: "STATUS_REJECTED",
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    setIsSaving(true);
    saveFiduciaryStatus(token, {
      id: currentItem.id,
      uaStatus: "REJECTED",
      note: JSON.stringify(dataNote),
    })
      .then((resp) => {
        toast.success(_("ua_status_successfully"));
        queryClient.invalidateQueries("listFiduciaries");
        setIsSaving(false);
        setShowRejectModal(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleDeleteAction = () => {
    let dataNote = currentItem.note ? currentItem.note : [];
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    dataNote.push({
      date: strDate,
      msg: "STATUS_DELETED",
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    setIsSaving(true);
    saveFiduciaryStatus(token, {
      id: currentItem.id,
      uaStatus: "DELETED",
      onBoardingStep: "CREATED",
      note: JSON.stringify(dataNote),
    })
      .then((resp) => {
        toast.success(_("ua_status_successfully"));
        queryClient.invalidateQueries("listFiduciaries");
        setIsSaving(false);
        setShowDeleteModal(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <div className="grid-container">
      <div className={styles.header}>
        <div>
          <h1 className={styles.pageTitle}>Liste des fiduciaires</h1>
          <p className={styles.pageHelpTitle}>
            Gérer ici les status des fiduciaires
          </p>
        </div>

        {auth.user && auth.user.type == "ADMIN" && (
          <div>
            <Button
              size="sm"
              variant="primary"
              onClick={() => setShowCommunityModal(true)}
            >
              Ajouter une communauté
            </Button>
          </div>
        )}
      </div>

      <div className={styles.filterBar}>
        <div className={styles.filter}>
          <label>Filtrer par statut</label>
          <Select
            styles={SELECT_STYLES}
            options={statusOptions}
            isSearchable={false}
            value={status}
            onChange={(e) => {
              setCurrentPage(1);
              setStatus(e);
            }}
          />
        </div>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder={_("search")}
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>

      <table
        className={`${styles.table} unstriped`}
        onClick={() => setDropDownId(null)}
      >
        <thead>
          <tr>
            <th width="20%" className={styles.nowrap}>
              <span>{_("uen")}</span>
              <span className={styles.sortBox}>
                <i
                  className={`icon-ttp-arrow-down ${
                    sort.property === "uen" &&
                    sort.dir === "asc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "uen", dir: "asc" })}
                ></i>
                <i
                  className={`icon-ttp-arrow-up ${
                    sort.property === "uen" &&
                    sort.dir === "desc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "uen", dir: "desc" })}
                ></i>
              </span>
            </th>
            <th width="25%">
              <span>Nom</span>
              <span className={styles.sortBox}>
                <i
                  className={`icon-ttp-arrow-down ${
                    sort.property === "name" &&
                    sort.dir === "asc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "name", dir: "asc" })}
                ></i>
                <i
                  className={`icon-ttp-arrow-up ${
                    sort.property === "name" &&
                    sort.dir === "desc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "name", dir: "desc" })}
                ></i>
              </span>
            </th>
            <th width="20%">Représentant</th>
            <th>Email du représentant</th>
            <th>{_("phone_number")}</th>
            <th>Représentant Temp</th>
            <th>Statut</th>
            <th>OECCBB formule</th>
            <th>Mode</th>
            <th width="8%">Date</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={9} className={styles.centerCell}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#B2BCC6"}
                />
              </td>
            </tr>
          ) : data?.data && data.data.length > 0 ? (
            data.data.map((item) => {
              let tmpLR = null;
              item.allLegalRepresentative.forEach((lr) => {
                if (lr.roleType === "TMP_LR") {
                  tmpLR = lr;
                }
              });
              return (
                <tr key={item.id}>
                  <td>{item.uen}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.allLegalRepresentative[0].firstName}{" "}
                    {item.allLegalRepresentative[0].lastName}
                  </td>
                  <td>{item.allLegalRepresentative[0].mainEmail}</td>
                  <td className={styles.nowrap}>
                    {item.allLegalRepresentative[0].mainPhone &&
                      formatPhone(item.allLegalRepresentative[0].mainPhone)}
                  </td>
                  <td>
                    {tmpLR ? (
                      <>
                        {tmpLR.firstName} {tmpLR.lastName}
                        <br />
                        {tmpLR.mainEmail}
                      </>
                    ) : null}
                  </td>
                  <td className={styles.statusCol}>
                    <span
                      className={`${styles.status} ${
                        styles[item.uaStatus.toLowerCase()]
                      }`}
                    >
                      {item.uaStatus}
                    </span>
                  </td>
                  <td>
                    {item?.membershipOrder?.items?.length > 0 ? (
                      <ul className={styles.list}>
                        {item.membershipOrder.items.map((i) => (
                          <li key={i.id}>{i.name}</li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {item.uaMode === "OECCBB_MEMBER"
                      ? "OECCBB"
                      : item.uaStatus === "VALIDATED"
                      ? item.uaMode
                      : ""}
                  </td>
                  <td>{item.createdAt}</td>
                  <td>{getActions(item)}</td>
                  <td>
                    <span
                      title="History"
                      onClick={() => {
                        setCurrentItem(item);
                        setShowHistoryModal(true);
                      }}
                    >
                      <i className="icon-ttp-clock"></i>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                Liste vide (aucune fiduciaire ne répond aux filtres)
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data?.nbResult && (
        <div className={styles.pagination}>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data.nbResult}
            pageSize={LIMIT}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
      {/* <ModalConfirm
        type="send"
        isOpen={showValidateModal}
        onCancel={() => {
          setShowValidateModal(false);
        }}
        onConfirm={() => handleValidateAction()}
        inProcess={isSaving}
        title={_("ua_validate_confirm")}
        text={_("text_ua_validate_confirm")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      /> */}
      <ModalConfirm
        type="publish"
        isOpen={showPendingModal}
        onCancel={() => {
          setShowPendingModal(false);
        }}
        onConfirm={() => handlePutOnPendingAction()}
        inProcess={isSaving}
        title={_("ua_validate_confirm")}
        text={_("text_ua_put_on_pending_confirm")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type="delete"
        isOpen={showRejectModal}
        onCancel={() => {
          setShowRejectModal(false);
        }}
        onConfirm={() => handleRejectAction()}
        inProcess={isSaving}
        title={_("ua_validate_confirm")}
        text={_("text_ua_reject_confirm")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type="delete"
        isOpen={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={isSaving}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <FiduciaryModal
        modalOpen={showFiduciaryModal}
        setModalOpen={setShowFiduciaryModal}
        fiduciary={currentItem}
        refreshList={() => queryClient.invalidateQueries("listFiduciaries")}
      />
      <FiduciaryAcceptModal
        modalOpen={showValidateModal}
        setModalOpen={setShowValidateModal}
        fiduciary={currentItem}
        refreshList={() => queryClient.invalidateQueries("listFiduciaries")}
      />
      <NoteModal
        modalOpen={showNoteModal}
        setModalOpen={setShowNoteModal}
        fiduciary={currentItem}
        refreshList={() => queryClient.invalidateQueries("listFiduciaries")}
      />
      <HistoryModal
        modalOpen={showHistoryModal}
        setModalOpen={setShowHistoryModal}
        fiduciary={currentItem}
      />
      <CommunityModal
        modalOpen={showCommunityModal}
        setModalOpen={setShowCommunityModal}
        afterSave={() => {
          if (status.value !== "VALIDATED") {
            setStatus(statusOptions[1]);
          } else {
            queryClient.invalidateQueries("listFiduciaries");
          }
        }}
      />
    </div>
  );
};

export default ManageFiduciary;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import classnames from "classnames";
import parse from "html-react-parser";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import {
  COUNTRIES,
  DAYS,
  NL_PLANS,
  MODE_Options,
  AGREATION_OPTIONS,
  AGREATION_INPUT_SIZE,
} from "../../../config";
import _ from "../../../i18n";
import {
  saveAdminFiduciary,
  getFolderByUen,
  getFolderInfoByUen,
  saveAppsSettings,
} from "../../../api";
import { validateUen, formatUen } from "../../../utils";

import InlineAgreationNumber from "../../common/AgreationNumber/InlineAgreationNumber";
import Loader from "../../common/Loader";
import Button from "../../common/Button";
import CheckboxCard from "../../common/CheckboxCard";
import Checkbox from "../../common/Checkbox";
import FormInput from "../../common/FormInput";
import FormSelect from "../../common/FormSelect";
import styles from "./CommunityModal.module.scss";

const typeOptions = [
  { value: "NORMAL", label: "NORMAL" },
  { value: "MASTER_CHAIN", label: "MASTER_CHAIN" },
  { value: "SUPER_CHAIN", label: "SUPER_CHAIN" },
  { value: "UNIVERSITY", label: "UNIVERSITY" },
];

const CommunityModal = ({ modalOpen, setModalOpen, afterSave }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setFetching] = useState(false);

  const [existFiduciary, setExistFiduciary] = useState(null); // Fiduciry exist with same uen
  const [files, setFiles] = useState([]);
  const [id, setId] = useState(null);
  const [logo, setLogo] = useState("");
  const [agreation, setAgreation] = useState(AGREATION_OPTIONS[0]);
  const [agreationNumber, setAgreationNumber] = useState("");
  const [agreationError, setAgreationError] = useState("");
  const [uen, setUen] = useState("");
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [url, setUrl] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState({ value: "BE", label: COUNTRIES.BE });
  const [businessType, setBusinessType] = useState(typeOptions[0]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [size, setSize] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [sizeScroll, setSizeScroll] = useState("RIGHT");

  const [uenError, setUenError] = useState("");
  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [isDisabed, setIsDisabed] = useState(false);
  const [uaMode, setUaMode] = useState(MODE_Options[0]);
  const [jumpPaymentStep, setJumpPaymentStep] = useState(false);
  const [jumpOnboarding, setJumpOnboarding] = useState(false);
  const [newsletterPlan, setNewsletterPlan] = useState(NL_PLANS[0]);
  const [tools, setTools] = useState([
    {
      type: "BLOG_NL_CREATE",
      config: newsletterPlan.value,
      basicPlan: newsletterPlan.value,
      frequency:
        newsletterPlan.value === "PERSONALIZED" ? "BY_WEEK" : "BY_MONTH",
      selectedWeek: "",
      senders: [],
      isAutomatic: false,
      day: DAYS[0],
      hour: {
        value: "00",
        label: "00",
      },
      minute: {
        value: "00",
        label: "00",
      },
      isLogoUaVisible: true,
    },
  ]);

  useEffect(() => {
    let tab = [];
    for (let i = 1; i <= 12; i++) {
      tab.push(i + "-" + i * 4);
    }
    setSizeOptions([...size, ...tab]);
  }, []);

  const handleChange = (e) => {
    let name = e.target.name;
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1))(e.target.value);
    // handleValidation();
  };

  const isValidForm = () => {
    setUenError(
      agreation?.value !== "UNIVERSITY"
        ? !validateUen(uen)
          ? _("validate_uen")
          : ""
        : ""
    );
    setNameError(name.length === 0 ? _("required_field") : "");
    setAddress1Error(address1.length === 0 ? _("required_field") : "");
    setZipCodeError(zipCode.length === 0 ? _("required_field") : "");
    setCityError(city.length === 0 ? _("required_field") : "");
    setSizeError(
      size.length === 0 && agreation?.value !== "UNIVERSITY"
        ? _("required_field")
        : ""
    );
    setAgreationError(!isValidType(agreationNumber) ? _("required_field") : "");

    return (
      ((uen !== "" && agreation?.value !== "UNIVERSITY") ||
        agreation?.value === "UNIVERSITY") &&
      name !== "" &&
      address1 !== "" &&
      zipCode !== "" &&
      city !== "" &&
      ((size !== "" && type !== "UNIVERSITY") || type === "UNIVERSITY") &&
      isValidType(agreationNumber)
    );
  };

  const isValidType = (value) => {
    return (
      !["ITAA", "IRE_AUDIT"].includes(agreation?.value) ||
      (["ITAA", "IRE_AUDIT"].includes(agreation?.value) &&
        AGREATION_INPUT_SIZE[agreation?.value] === value.length)
    );
  };

  const handleAgreation = (e) => {
    setAgreation(e);
  };

  const handleNumberAgreation = (e) => {
    setAgreationNumber(e);
    setAgreationError(!isValidType(e) ? _("required_field") : "");
  };

  const handleUenKeyUp = (value) => {
    let error = "";
    if (!validateUen(value)) {
      error = _("validate_uen");
    } else {
      error = "";
    }
    if (value.length === 0) {
      error = _("required_field");
    }
    setUenError(error);
  };

  const handleUenBlur = (value) => {
    if (validateUen(value)) {
      setUenError("");
      setFetching(true);
      getFolderByUen({
        token: auth.token,
        uen: value,
      })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            const folder = response.data.data[0];
            if (folder.onBoardingStep === "COMPLETE") {
              setExistFiduciary(folder);
            } else {
              setExistFiduciary(null);
              let agreationType = "ITAA";
              if (folder.officeType) {
                let ag = AGREATION_OPTIONS.filter(
                  (i) => i.value === folder.officeType
                );
                if (ag && ag.length === 1) {
                  agreationType = folder.officeType;
                  setAgreation(ag[0]);
                } else {
                  setAgreation(AGREATION_OPTIONS[0]);
                }
              }
              setId(folder.id);
              setName(folder.name);
              setSize(folder.officeSize ?? "");
              setAddress1(folder.address1);
              setZipCode(folder.zipCode);
              setCity(folder.city);
              if (folder.uaMode) {
                const mode = MODE_Options.filter(
                  (i) => i.value === folder.uaMode
                );
                if (mode.length > 0) {
                  setUaMode(mode[0]);
                }
              }
              if (folder.businessType) {
                const cmType = typeOptions.filter(
                  (i) => i.value === folder.businessType
                );
                if (cmType.length > 0) {
                  setBusinessType(cmType[0]);
                }
              }
              if (folder.avatarUrl) {
                setLogo(folder.avatarUrl);
              }
            }
          }
          setFetching(false);
        })
        .catch((e) => {
          clearFields(false);
          if (e.response && e.response.status === 404) {
            getFolderInfoByUen({
              token: auth.token,
              uen: value.replace(
                /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
                "BE$2$3$4"
              ),
            })
              .then((res) => {
                setFetching(false);
                const resp = res.data.data;
                if (resp.isValid) {
                  const address = resp.address.split("\n");
                  const addressStr =
                    address.length > 0 ? address[0] : resp.address;
                  setUen(formatUen(value));
                  setName(resp.name);
                  setAddress1(addressStr);
                  setNameError("");
                } else {
                  setUen(formatUen(value));
                }
              })
              .finally(() => {
                setFetching(false);
              });
          } else {
            setFetching(false);
            setUen(formatUen(value));
          }
        });
    } else {
      if (value.length === 0) {
        setUenError(_("required_field"));
      } else {
        setUenError(_("validate_uen"));
      }
    }
  };

  const handleAddressChange = (address) => {
    let message = address.length === 0 ? _("required_field") : "";
    setAddress1Error(message);
    setAddress1(address);
    // handleValidation();
  };

  const handleSelect = (address) => {
    setAddress1(address);
    geocodeByAddress(address)
      .then((results) => results[0].address_components)
      .then((addressComponents) => {
        const country = addressComponents.filter((address) =>
          address.types.includes("country")
        );
        const zipCode = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        if (city.length > 0) setCity(city[0].long_name);
        if (zipCode.length > 0) setZipCode(zipCode[0].long_name);
        if (country.length > 0 && COUNTRIES[country[0].short_name])
          setCountry({
            value: country[0].short_name,
            label: COUNTRIES[country[0].short_name],
          });
      })
      .catch((error) => console.error("Error", error));
  };

  const requiredField = (e) => {
    let name = e.target.name;
    let message = e.target.value.length === 0 ? _("required_field") : "";
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1) + "Error")(
      message
    );
  };

  const handleSave = async () => {
    // console.log(isValidForm());
    // return null;

    setIsSaving(true);
    const data = {
      token: auth.token,
      files,
      id,
      uen,
      name,
      abbreviation: abbreviation,
      address1,
      zipCode,
      city,
      officeType: agreation?.value,
      officeSize: size,
      agreation: {
        type: agreation?.value,
        number: agreationNumber.replace(/[ .]/g, ""),
      },
      country: country ? country.value : "",
      uaStatus: "VALIDATED",
      uaMode: uaMode.value,
      url: "",
      businessType: businessType ? businessType.value : "NORMAL",
    };
    if (jumpOnboarding) {
      data.onBoardingStep = "COMPLETE";
    } else if (jumpPaymentStep) {
      data.onBoardingStep = "PAYMENT";
    }

    saveAdminFiduciary(data)
      .then(async (resp) => {
        if (resp.data.result === "OK") {
          try {
            await saveAppsSettings({
              token: auth.token,
              communityId: resp.data.data.id,
              data: {
                appName: "newsletter",
                tools: tools,
              },
            });
          } catch (e) {}
          setIsSaving(false);
          toast.success(_("successfully_added"));
          afterSave();
          closeModal();
        }
      })
      .catch((e) => {
        setIsSaving(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const handleClickSize = (size) => {
    setSize(size);
    setSizeError("");
  };

  const formatSize = (size) => {
    const tab = size.split("-");
    return tab.length === 2 ? `${tab[0]}+${tab[1]}` : tab[0];
  };

  const handleNextSize = () => {
    let lastSize = sizeOptions[sizeOptions.length - 1];
    lastSize = lastSize.split("-");
    const min = parseInt(lastSize[0]) + 1;
    const newSize = min + "-" + min * 4;
    setSizeOptions([...sizeOptions, newSize]);

    const el = document.getElementById("list");
    el.scrollLeft += 300;
    if (el.scrollLeft + 0.5 === el.scrollWidth - el.offsetWidth) {
      setSizeScroll("LEFT");
    } else if (el.scrollLeft > 0) {
      setSizeScroll("BOTH");
    }
  };

  const clearFields = (clearUen = true) => {
    if (clearUen) {
      setUen("");
    }
    setId("");
    setExistFiduciary(null);
    setName("");
    setSize("");
    setAddress1("");
    setZipCode("");
    setCity("");
    setAgreationNumber("");
    setAgreation(AGREATION_OPTIONS[0]);
    setUaMode(MODE_Options[0]);
    setBusinessType(typeOptions[0]);
    setFiles([]);
    setLogo("");
    setJumpPaymentStep(false);
    setJumpOnboarding(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    clearFields();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });
  const bgImg = files.length ? files[0].preview : logo;
  const style = useMemo(
    () => ({
      ...{
        backgroundImage: "URL(" + bgImg + ")",
      },
    }),
    [isDragActive, isDragReject, isDragAccept, bgImg]
  );

  return (
    <Modal
      width="80vw"
      height="50vh"
      closable={false}
      open={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        closeModal();
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>Ajouter une communauté</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          closeModal();
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      {isFetching && <div className="lmask"></div>}
      <div className={styles.content}>
        {existFiduciary && (
          <div className={styles.topBar}>
            La communauté {existFiduciary.name} est déjà active
          </div>
        )}
        <div className="grid-x grid-margin-x">
          <div className="d-flex-col cell large-3">
            <label className="ttp-label">{_("company_logo")}</label>
            <div className={styles.dropzone} {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <div
                className="icon-picture"
                style={{
                  display: files.length > 0 ? "none" : "block",
                }}
              >
                <i className="icon-ttp-picture"></i>
              </div>
            </div>
          </div>
          <div className="cell small-12 large-9 grid-x grid-margin-x">
            <div className="cell small-12 large-3">
              <FormInput
                name="uen"
                placeholder="BE XXXX.XXX.XXX"
                label={_("business_number")}
                info={_("uen_info")}
                error={uenError}
                value={uen}
                autoComplete="none"
                onKeyUp={(e) => handleUenKeyUp(e.target.value)}
                handleBlur={(e) => handleUenBlur(e.target.value)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>

            <div className="cell small-12 large-3">
              <FormInput
                name="name"
                value={name}
                required={true}
                label={_("company_name")}
                error={nameError}
                autoComplete="off"
                // handleBlur={(e) => handleBlur(e.target.name, e.target.value)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-4">
              <FormInput
                name="abbreviation"
                value={abbreviation}
                label={_("Abréviation")}
                onChange={(e) => handleChange(e)}
                autoComplete="none"
              ></FormInput>
            </div>
            <div className="cell large-4">
              <FormInput
                name="url"
                value={url}
                label={_("Lien URL")}
                autoComplete="none"
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-8">
              <div class="FormInput_group__CrYQj">
                <InlineAgreationNumber
                  NumeroAgreation={agreationNumber}
                  Agreation={agreation}
                  setAgreation={handleAgreation}
                  setNumeroAgreation={handleNumberAgreation}
                  small={true}
                  error={agreationError}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-6">
            <label className="ttp-label">
              {_("Adresse")}
              <span className="star">*</span>
            </label>
            <PlacesAutocomplete
              value={address1}
              onChange={handleAddressChange}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: ["be"] },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="group-input">
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input",
                    })}
                    autoComplete="none"
                    name="address1"
                    disabled={isDisabed}
                    onBlur={(e) => {
                      requiredField(e);
                    }}
                  />
                  <span className="ttp-error">{address1Error}</span>
                  {suggestions.length > 0 ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="cell small-12 large-6 grid-x grid-margin-x">
            <div className="cell small-12 large-4">
              <FormInput
                name="zipCode"
                value={zipCode}
                label={_("Code postal")}
                required={true}
                error={zipCodeError}
                autoComplete="none"
                disabled={isDisabed}
                handleBlur={(e) => requiredField(e)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-4">
              <FormInput
                name="city"
                value={city}
                label={_("City")}
                required={true}
                error={cityError}
                autoComplete="none"
                disabled={isDisabed}
                handleBlur={(e) => requiredField(e)}
                onChange={(e) => handleChange(e)}
              ></FormInput>
            </div>
            <div className="cell small-12 large-4">
              <FormSelect
                name="country"
                value={country}
                label={_("Country")}
                required={true}
                disabled={isDisabed}
                handleChange={(option) => setCountry(option)}
                options={Object.entries(COUNTRIES).map(([k, v]) => {
                  return { value: k, label: v };
                })}
              />
            </div>
          </div>
        </div>

        <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell large-3">
            <FormSelect
              value={businessType}
              label="Type de communauté"
              handleChange={(option) => setBusinessType(option)}
              options={typeOptions}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              value={uaMode}
              label="Choisissez le mode à attribuer"
              handleChange={(option) => setUaMode(option)}
              options={MODE_Options}
            />
          </div>

          <div className="cell small-12 large-3">
            <FormSelect
              name="plan"
              value={newsletterPlan}
              label={_("choose_plan")}
              handleChange={(option) => {
                setNewsletterPlan(option);
                let toolsUpdates = tools.map((tool) => {
                  if (tool.type === "BLOG_NL_CREATE") {
                    return {
                      ...tool,
                      config: option.value,
                      frequency:
                        option.value === "PERSONALIZED"
                          ? "BY_WEEK"
                          : "BY_MONTH",
                    };
                  }
                  return tool;
                });
                setTools(toolsUpdates);
              }}
              options={NL_PLANS}
            />
          </div>
        </div>

        <div className="cell small-12 large-6 grid-x grid-margin-x">
          <div className={`cell small-12 large-4 ${styles.checkbox}`}>
            <Checkbox
              checked={jumpPaymentStep}
              onClick={() => {
                if (jumpOnboarding) {
                  setJumpOnboarding(false);
                }
                setJumpPaymentStep(!jumpPaymentStep);
              }}
            />
            <label
              onClick={() => {
                if (jumpOnboarding) {
                  setJumpOnboarding(false);
                }
                setJumpPaymentStep(!jumpPaymentStep);
              }}
            >
              Sauter le paiement
            </label>
          </div>

          <div className={`cell small-12 large-4 ${styles.checkbox}`}>
            <Checkbox
              checked={jumpOnboarding}
              onClick={() => {
                if (jumpPaymentStep) {
                  setJumpPaymentStep(false);
                }
                setJumpOnboarding(!jumpOnboarding);
              }}
            />
            <label
              onClick={() => {
                if (jumpPaymentStep) {
                  setJumpPaymentStep(false);
                }
                setJumpOnboarding(!jumpOnboarding);
              }}
            >
              Sauter l'onboarding
            </label>
          </div>
        </div>

        <hr className={styles.separator} />

        <label className={styles.label}>
          {_("number_collaborator")} <span className="star">*</span>
        </label>

        <div className={styles.checkbox} style={{ margin: "10px 0" }}>
          <Checkbox checked={size === ""} onClick={() => setSize("")} />
          <label onClick={() => setSize("")}>Nombre illimité</label>
        </div>
        <div className={styles.sizeBox}>
          <div className={styles.sizeBar}>
            {(sizeScroll === "LEFT" || sizeScroll === "BOTH") && (
              <div
                className={classnames(
                  styles.scrollButton,
                  styles.scrollButtonBack
                )}
              >
                <button
                  onClick={() => {
                    const el = document.getElementById("list");
                    el.scrollLeft -= 300;
                    if (el.scrollLeft === 0) {
                      setSizeScroll("RIGHT");
                    } else if (el.scrollLeft > 0) {
                      setSizeScroll("BOTH");
                    }
                  }}
                >
                  <i className="icon-ttp-chevron-up" />
                </button>
              </div>
            )}

            <ul id="list">
              {sizeOptions.map((s, i) => (
                <li key={`size-${i}`}>
                  <CheckboxCard
                    // disabled={isOeccbbMember}
                    onClick={() => handleClickSize(s)}
                    selected={size === s}
                    icon="/img/collaborators.png"
                    html={parse(
                      `<span>${formatSize(s)}</span> ${_("Collab.")}`
                    )}
                  />
                </li>
              ))}
            </ul>

            <div
              className={classnames(
                styles.scrollButton,
                styles.scrollButtonForward
              )}
            >
              <button
                onClick={() => {
                  handleNextSize();
                }}
              >
                <i className="icon-ttp-chevron-up" />
              </button>
            </div>
          </div>
          <span className={styles.errorCol}>{sizeError}</span>
        </div>

        {/* <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-3">
            <FormSelect
              name="legalForm"
              value={legalForm}
              label={_("Forme Légal")}
              required={true}
              handleChange={(option) => setLegalForm(option)}
              options={Object.entries(LEGAL_FORM).map(([k, v]) => {
                return { value: k, label: v };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              name="sector"
              value={sector}
              label={_("Secteur d'activité")}
              handleChange={(option) => setSector(option)}
              options={SECTORS.map((sect) => {
                return { value: sect.id, label: sect.title };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              name="tva"
              value={tva}
              label={_("Type de TVA")}
              required={true}
              handleChange={(option) => setTva(option)}
              options={Object.entries(TVA_TYPE).map(([k, v]) => {
                return { value: k, label: v };
              })}
            />
          </div>
          <div className="cell small-12 large-3">
            <FormSelect
              isMulti
              name="languagePreferences"
              value={languagePreferences}
              label={_("Préférences de langues")}
              required={true}
              options={LANGUAGES}
              error={languagePreferencesError}
              handleBlur={handleLanguageBlur}
              handleChange={handleLanguageChange}
            />
          </div>
        </div> */}
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default CommunityModal;

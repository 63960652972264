import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "../../../i18n";
import { saveFolderStep, getArticles } from "../../../api";
import { setOnBoardingStep } from "../../../store";
import Button from "../../common/Button";
import CollaboratorBox from "./CollaboratorBox";
import CollaboratorList from "./CollaboratorList";
import styles from "./Collaborator.module.scss";

const Collaborator = (props) => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [currentItem, setCurrentItem] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [nbCollaborators, setNbCollaborators] = useState(0);
  const dispatch = useDispatch();

  const handleAfterSave = () => {
    setRefreshList(true);
  };

  const handleNextStep = () => {
    if (fiduciary.onBoardingStep === "COMPANY") {
      const step =
        fiduciary.uaMode === "UNIVERSITY" ? "COMPLETE" : "COLLABORATORS";
      setIsSaving(true);
      saveFolderStep(token, { step, id: fiduciary.id })
        .then((resp) => {
          dispatch(setOnBoardingStep(step));
          setIsSaving(false);
          if (step === "COMPLETE") {
            navigate("/settings");
          } else {
            props.setCurrentStep(4);
          }
        })
        .catch((e) => {
          setIsSaving(false);
        });
    } else {
      if (step === "COMPLETE") {
        navigate("/settings");
      } else {
        props.setCurrentStep(4);
      }
    }
  };

  return (
    <div className="grid-container">
      {isSaving ? <div className="lmask"></div> : null}
      <h1 className={styles.pageTitle}>
        {fiduciary.uaMode === "UNIVERSITY"
          ? _("add_student_title")
          : _("add_collaborator_title")}
      </h1>

      <p className={styles.pageHelpTitle}>
        Vous disposez de 3 méthodes d’ajout. Nous vous recommandons
        l’importation via le fichier excel.
      </p>

      <div className="grid-x grid-margin-x">
        <div className="cell small-12 medium-12 large-5">
          <CollaboratorBox
            afterSave={handleAfterSave}
            currentItem={currentItem}
            resetForm={resetForm}
            setResetForm={setResetForm}
            nbCollaborators={nbCollaborators}
            noLimit={fiduciary.uaMode === "UNIVERSITY" || !fiduciary.officeSize}
          />
        </div>
        <div className="cell small-12 medium-12 large-7">
          <CollaboratorList
            onEdit={(e) => setCurrentItem(e)}
            setCurrentItem={setCurrentItem}
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            afterDelete={() => setResetForm(true)}
            setNbCollaborators={setNbCollaborators}
          />
        </div>
      </div>

      <div className={styles.actionBar}>
        <div className="grid-container">
          <Button
            variant="default"
            icon="ttp-double-arrow-left"
            onClick={() => props.setCurrentStep(props.currentStep - 1)}
          >
            {_("retour").toUpperCase()}
          </Button>

          <Button iconRight="ttp-double-arrow-right" onClick={handleNextStep}>
            {_("continue").toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Collaborator;

import React from "react";
import classNames from "classnames";

import styles from "./Contact.module.scss";
import EmailIcon from "../icons/EmailIcon";
import PhoneIcon from "../icons/PhoneIcon";
import LocationIcon from "../icons/LocationIcon";
import ClockIcon from "../icons/ClockIcon";
import Map from "./Map";
import _ from "../../../../../../../../i18n";

const Contact = ({ navCommunity, publicPortal, version, onEdit }) => {
  const renderSchedulePart = (schedule, moment) => {
    return (
      <td
        className={classNames(
          schedule[moment].FROM === "NO_WORK" ||
            schedule[moment].FROM === "" ||
            schedule[moment].TO === ""
            ? styles.closed
            : "",
          moment === "MORNING" ? styles.morning : ""
        )}
      >
        {schedule[moment].FROM === "NO_WORK" ||
        schedule[moment].FROM === "" ||
        schedule[moment].TO === ""
          ? "Fermé"
          : `${schedule[moment].FROM.replace(/_/g, "0")} > ${schedule[
              moment
            ].TO.replace(/_/g, "0")}`}
      </td>
    );
  };

  const renderSchedule = () => {
    let scheduleBlocs = [];
    let check = [];
    for (const [key, value] of Object.entries(
      publicPortal.config.contact.schedule
    )) {
      check.push(
        value.MORNING.FROM,
        value.MORNING.TO,
        value.AFTERNOON.FROM,
        value.AFTERNOON.TO
      );
      scheduleBlocs.push(
        <tr key={key}>
          <td className={styles.day}>{_(`${key}_full`)}</td>
          {renderSchedulePart(value, "MORNING")}
          {renderSchedulePart(value, "AFTERNOON")}
        </tr>
      );
    }

    return (
      <table>
        <tbody>{scheduleBlocs}</tbody>
      </table>
    );
  };

  return (
    <div className={styles.contact_section}>
      {["v1", "v2"].includes(version) && (
        <div className={styles.section_title}>{"Contact"}</div>
      )}
      {["v1", "v2"].includes(version) && (
        <div className={styles.title}>{"Contact us"}</div>
      )}

      {version === "v3" && (
        <div className={styles.flex}>
          {publicPortal.config.contact.latitude &&
            publicPortal.config.contact.longitude && (
              <div className={styles.map}>
                <Map
                  latitude={publicPortal.config.contact.latitude}
                  longitude={publicPortal.config.contact.longitude}
                />
              </div>
            )}
          <div className={styles.form}>
            <div className={styles.section_title}>{"Contact"}</div>
            <div className={styles.title}>{"Contact us"}</div>
            <div className={styles.formInput}>
              <label>{"Name"}</label>
              <input type="text" />
            </div>
            <div className={styles.formInput}>
              <label>{"Email"}</label>
              <input type="text" />
            </div>
            <div className={styles.formInput}>
              <label>{"Subject"}</label>
              <input type="text" />
            </div>
            <div className={styles.formInput}>
              <label>{"Message"}</label>
              <textarea></textarea>
            </div>
            <div className={styles.formCheck}>
              <div className={classNames(styles.check)}>{""}</div>
              {"I accept the Terms"}
            </div>
            <div className={classNames(styles.btn)}>{"Send Message"}</div>
          </div>
        </div>
      )}

      <div className={classNames(styles.list)}>
        {publicPortal.config.contact.email?.length > 0 && (
          <div className={classNames(styles.cardCont)}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <EmailIcon />
              </div>
              <div className={styles.title}>{"Email"}</div>
              <div className={styles.value}>
                {publicPortal.config.contact.email}
              </div>
            </div>
          </div>
        )}
        {publicPortal.config.contact.phone?.length > 0 && (
          <div className={classNames(styles.cardCont)}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <PhoneIcon />
              </div>
              <div className={styles.title}>{"Phone"}</div>
              <div className={styles.value}>
                {publicPortal.config.contact.phone}
              </div>
            </div>
          </div>
        )}
        {navCommunity.address1.length > 0 && (
          <div className={classNames(styles.cardCont)}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <LocationIcon />
              </div>
              <div className={styles.title}>{"Office"}</div>
              <div className={styles.value}>
                {publicPortal.config.contact?.address ?? navCommunity.address1}{" "}
                {publicPortal.config.contact?.zipCode ?? navCommunity.zipCode}{" "}
                {publicPortal.config.contact?.city ?? navCommunity.city}
              </div>
            </div>
          </div>
        )}
        <div className={classNames(styles.cardCont)}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <ClockIcon />
            </div>
            <div className={styles.title}>{"Horaires"}</div>
            <div className={styles.schedule}>{renderSchedule()}</div>
          </div>
        </div>
      </div>
      {!onEdit &&
        ["v1", "v2"].includes(version) &&
        publicPortal.config.contact.latitude &&
        publicPortal.config.contact.longitude && (
          <div className={styles.map}>
            <Map
              latitude={publicPortal.config.contact.latitude}
              longitude={publicPortal.config.contact.longitude}
            />
          </div>
        )}
    </div>
  );
};

export default Contact;

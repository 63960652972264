import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  MemberMediaAppPublic,
  AboutAppPublic,
  SingleServiceAppPublic,
  BlogAppPublic,
  TeamAppPublic,
  ContactAppPublic,
} from "../../PortalApps";
import MediaSlider from "./MemberBlocs/MediaSlider";
import EditContainer from "./MemberBlocs/EditContainer";
import ConfigModal from "./ConfigModal";
import SingleService from "./MemberBlocs/SingleService";
import About from "./MemberBlocs/About";
import News from "./MemberBlocs/News";
import Team from "./MemberBlocs/Team";
import Contact from "./MemberBlocs/Contact";

const MemberV1 = ({
  handlePortalBlocChange,
  portals,
  memberMedias,
  setMemberMedias,
  orderMemberSettings,
  titleAttr,
  handlePortalBlocTitleChange,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  aboutImage,
  setAboutImage,
  handleAboutPageBlocChange,
  handleAboutPageBlocTitleChange,
  handleAboutPageBlocDescriptionChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  singleServiceImage,
  setSingleServiceImage,
  sortCollaborators,
  collaborators,
  setCollaborators,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
}) => {
  const [editSection, setEditSection] = useState(null);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  return (
    <>
      <EditContainer
        isVisible={portals.public.config.memberMedia.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.memberMedia.activated,
            "memberMedia"
          )
        }
        edit={() => setEditSection("memberMedia")}
      >
        <MediaSlider images={memberMedias.slice(0, 3)} />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.about.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.about.activated,
            "about"
          )
        }
        edit={() => setEditSection("about")}
      >
        <About image={aboutImage} data={portals.public.config.about} />
      </EditContainer>

      <EditContainer
        isVisible={portals.public.config.singleService.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.singleService.activated,
            "singleService"
          )
        }
        edit={() => setEditSection("singleService")}
      >
        <SingleService
          service={portals.public.config.singleService}
          singleServiceImage={singleServiceImage}
        />
      </EditContainer>

      <EditContainer
        isVisible={portals.public.config.news.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.news.activated, "news")
        }
        edit={() => setEditSection("news")}
      >
        <News data={portals.public.config.news} version={"v1"} />
      </EditContainer>

      <EditContainer
        isVisible={portals.public.config.team.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.team.activated, "team")
        }
        edit={() => setEditSection("team")}
      >
        <Team
          data={portals.public.config.team}
          team={collaborators.filter((el) => el.enableAvatar).slice(0, 4)}
        />
      </EditContainer>

      <EditContainer
        isVisible={portals.public.config.contact.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.contact.activated,
            "contact"
          )
        }
        edit={() => setEditSection("contact")}
      >
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v1"}
          onEdit={editSection === "contact"}
        />
      </EditContainer>

      <ConfigModal
        isOpen={editSection !== null}
        close={() => setEditSection(null)}
      >
        {editSection === "memberMedia" && (
          <MemberMediaAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            medias={memberMedias}
            setMedias={setMemberMedias}
            maxPicks={orderMemberSettings.v1.media.maxPick}
            inModal={true}
          />
        )}
        {editSection === "about" && (
          <AboutAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            inModal={true}
          />
        )}
        {editSection === "singleService" && (
          <SingleServiceAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            singleServiceImage={singleServiceImage}
            setSingleServiceImage={setSingleServiceImage}
            inModal={true}
          />
        )}
        {editSection === "news" && (
          <BlogAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            inModal={true}
          />
        )}
        {editSection === "team" && (
          <TeamAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            sortCollaborators={sortCollaborators}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            inModal={true}
          />
        )}
        {editSection === "contact" && (
          <ContactAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            handleGeoChange={handleGeoChange}
            addPushPinOnClick={addPushPinOnClick}
            handleChangeSchedule={handleChangeSchedule}
            handleApplySchedule={handleApplySchedule}
            contactEmailError={contactEmailError}
            setContactEmailError={setContactEmailError}
            contactPhoneError={contactPhoneError}
            setContactPhoneError={setContactPhoneError}
            inModal={true}
            community={fiduciary}
          />
        )}
      </ConfigModal>
    </>
  );
};

export default MemberV1;

import { useSelector } from "react-redux";

import Switch from "../../../common/Switch/Switch";
import DisabledSwitch from "../../../common/Switch/Switch/DisabledSwitch";
import _ from "../../../../i18n";

// import styles from "./WatchSharing.module.scss";
import styles from "./BlogSource.module.scss";

const BlogSource = ({ source, setSource, disabledItems = [] }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const handleChange = (isChecked, value) => {
    let tmp = [...source];
    if (isChecked) {
      tmp.push(value);
    } else {
      tmp = tmp.filter((i) => i !== value);
    }
    setSource(tmp);
  };

  return (
    <div>
      <h3 className={styles.title}>Configurez les sources d'articles</h3>

      <ul className={styles.blog_box}>
        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Ma bibliothèque</h3>
            {fiduciary.uaMode === "OECCBB_MEMBER" ? (
              <DisabledSwitch isChecked={false} />
            ) : (
              <Switch
                isChecked={source.includes("MY_LIBRARY")}
                onChange={(e) => handleChange(e, "MY_LIBRARY")}
              />
            )}
          </div>
          <p>{_("app_blog_tools_1_desc")}</p>
        </li>

        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Activer la bibliothèque oFFFcourse</h3>
            <Switch
              isChecked={source.includes("FFF_LIBRARY")}
              onChange={(e) => handleChange(e, "FFF_LIBRARY")}
            />
          </div>
          <p>
            Afficher les articles de Forum for the future. Vous aurez la
            possibilité de partager vos articles avec la bibliothèque oFFFcourse
          </p>
        </li>

        <li className={styles.blog_box_item}>
          <div className={styles.blog_box_item_header}>
            <h3>Partager avec les confrères</h3>
            {disabledItems.includes("COLLEAGUE") ? (
              <DisabledSwitch
                isChecked={false}
                // onChange={(e) => handleCheckApp(e, value)}
              />
            ) : (
              <Switch
                isChecked={source.includes("COLLEAGUE")}
                onChange={(e) => handleChange(e, "COLLEAGUE")}
              />
            )}
          </div>
          <p>Mes articles seront partager avec mes confrères</p>
        </li>
      </ul>
    </div>
  );
};

export default BlogSource;

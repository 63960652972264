import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import classnames from "classnames";
import Select from "react-select";

import { validateUen, formatUen } from "../../../../utils";
import _ from "../../../../i18n";
import FormInput from "../../../common/FormInput";
import Button from "../../../common/Button";
import Switch from "../../../common/Switch/Switch";
import Checkbox from "../../../common/Checkbox";

import styles from "./MemberOrderModal.module.scss";

const customModalStyles = {
  content: {
    backgroundColor: "#fff",
    border: "none",
    overflow: "none",
    padding: 0,
    top: "50%",
    bottom: "unset",
    height: "90%",
    maxHeight: "600px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(41, 57, 77, 0.04)",
    zIndex: 999,
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "70%",
    maxWidth: "900px",
  },
};

export default function MemberOrderModal({
  isOpen,
  closeModal,
  currentMemberOrder,
  onSave,
  formulaOptions,
  SELECT_STYLES,
}) {
  const [formulas, setFormulas] = useState(null);
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [uen, setUen] = useState("");
  const [organization, setOrganization] = useState("");
  const [subjectToVAT, setSubjectToVAT] = useState(true);
  const [errors, setErrors] = useState({
    formulas: "",
    uen: "",
    organization: "",
    address: "",
    postalCode: "",
    city: "",
  });
  const [discount, setDiscount] = useState({});
  const [discountValue, setDiscountValue] = useState({});

  useEffect(() => {
    if (isOpen && currentMemberOrder) {
      if (currentMemberOrder.billingOrganization) {
        setAddress(currentMemberOrder.billingStreet);
        setPostalCode(currentMemberOrder.billingPostalCode);
        setOrganization(currentMemberOrder.billingOrganization);
        setCity(currentMemberOrder.billingCountry);
        setSubjectToVAT(currentMemberOrder.billingSubjectToVAT);
        if (currentMemberOrder.billingCompanyNumber) {
          const formattedUen = currentMemberOrder.billingCompanyNumber.includes(
            "BE"
          )
            ? formatUen(currentMemberOrder.billingCompanyNumber)
            : formatUen("BE" + currentMemberOrder.billingCompanyNumber);
          setUen(formattedUen);
        }
      }
      let tab = [];
      currentMemberOrder.data.forEach((row) => {
        let formula = formulaOptions.filter(
          (i) => i.value === parseInt(row.formula)
        );

        if (formula) {
          tab.push(formula[0]);
        }
      });
      if (tab.length > 0) {
        setFormulas(tab);
      }
    }
  }, [isOpen, currentMemberOrder]);

  const handleCloseModal = () => {
    closeModal();
    setErrors({
      formulas: "",
      uen: "",
      organization: "",
      address: "",
      postalCode: "",
      city: "",
    });
    setAddress("");
    setPostalCode("");
    setCity("");
    setUen("");
    setOrganization("");
    setSubjectToVAT(true);
  };

  const handleVatClick = (e) => {
    e.stopPropagation();
    setSubjectToVAT(!subjectToVAT);
  };

  const handleUenKeyUp = (value) => {
    let error = "";
    if (!validateUen(value)) {
      error = _("validate_uen");
    } else {
      error = "";
    }
    if (value.length === 0) {
      error = _("required_field");
    }
    setErrors({ ...errors, uen: error });
  };

  const isValidForm = () => {
    let dataErrors = {
      ...errors,
      formulas: !formulas || formulas.length === 0 ? _("required_field") : "",
      uen: !validateUen(uen) && subjectToVAT ? _("validate_uen") : "",
      organization: organization.length === 0 ? _("required_field") : "",
      address: address.length === 0 ? _("required_field") : "",
      postalCode: postalCode.length === 0 ? _("required_field") : "",
      city: city.length === 0 ? _("required_field") : "",
    };
    setErrors(dataErrors);

    return (
      !dataErrors.formulas &&
      !dataErrors.uen &&
      !dataErrors.organization &&
      !dataErrors.address &&
      !dataErrors.postalCode &&
      !dataErrors.city
    );
  };

  const saveStep1 = () => {
    if (!isValidForm()) {
      return null;
    }

    const tabData = formulas.map((item) => {
      let tab = {
        formula: item.value,
        membersShip: item.membership,
        sliderValue: 1,
      };
      if (discount[item.value] && discountValue[item.value]) {
        tab.discount = discountValue[item.value];
      }
      return tab;
    });

    let data = {
      ...currentMemberOrder,
      data: tabData,
      uen,
      organization,
      address,
      postalCode,
      city,
      subjectToVAT,
    };
    onSave(data);
    handleCloseModal();
  };

  const handleAddressChange = (address) => {
    if (address.length === 0) {
      setErrors({ ...errors, address: _("required_field") });
    } else {
      setErrors({ ...errors, address: "" });
    }
    // setData({ ...data, address: address });
    setAddress(address);
  };

  const handleSelectAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((results) => {
        const addressComponents = results.address_components;
        const zip_code = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const comp_city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        const state = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("administrative_area_level_1")
        );
        const country = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("country")
        );
        const city = comp_city.length > 0 ? comp_city[0].long_name : "";
        const zipCode = zip_code.length > 0 ? zip_code[0].long_name : "";
        let cleanAdd = address;
        if (country.length > 0)
          cleanAdd = cleanAdd.replace(", " + country[0].long_name, "");
        if (state.length > 0)
          cleanAdd = cleanAdd.replace(", " + state[0].long_name, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(", " + city, "");

        setCity(city);
        setPostalCode(zipCode);
        setAddress(cleanAdd);

        setErrors({
          ...errors,
          postalCode: zipCode ? "" : _("required_field"),
          city: city ? "" : _("required_field"),
        });
      })
      .catch((error) => console.error("Error", error));
  };

  if (!currentMemberOrder) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      overlayClassName={styles.overlay}
      contentLabel="Tamtam payment"
    >
      <div className="rmodal">
        <div className={styles.close} onClick={handleCloseModal}>
          <i className="icon-ttp-close-outline"></i>
        </div>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>
            Remplissez les informations de facturation de:{" "}
            {currentMemberOrder.user.firstName +
              " " +
              currentMemberOrder.user.lastName}
          </h3>

          <div className="ttp-form-group">
            <label>Formule</label>
            <div className={styles.formulaFilter}>
              <Select
                isMulti
                styles={SELECT_STYLES}
                options={formulaOptions}
                isSearchable={false}
                value={formulas}
                onChange={(e) => setFormulas(e)}
                classNamePrefix="react-select"
                isOptionDisabled={(option) =>
                  formulas.filter((i) => i.membership === option.membership)
                    .length > 0
                }
              />
            </div>
            {errors.formulas && (
              <div className="ttp-error">{errors.formulas}</div>
            )}
          </div>

          {formulas?.length > 0 && (
            <div>
              <label className={styles.discountLabel}>
                Appliquer une réduction
              </label>

              {formulas.map((item) => {
                return (
                  <div className={styles.discountRow}>
                    <div className={styles.switchRow}>
                      <div className={styles.switchRow_left}>
                        <Switch
                          isChecked={discount[item.value]}
                          onChange={(e) =>
                            setDiscount({ ...discount, [item.value]: e })
                          }
                        />

                        <label className={styles.configLabel}>
                          {item.label}{" "}
                          <span className={styles.price}>
                            ({item.annualPrice}
                            <span>€ /an</span>)
                          </span>
                        </label>
                      </div>
                      {discount[item.value] && (
                        <div className={styles.switchRow_right}>
                          <label>Entrez le montant en €</label>
                          <input
                            type="number"
                            autoComplete="off"
                            value={discountValue[item.value]}
                            className="ttp-input"
                            onChange={(e) =>
                              setDiscountValue({
                                ...discountValue,
                                [item.value]: e.target.value,
                              })
                            }
                            style={{ width: "150px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <FormInput
                name="uen"
                required={subjectToVAT}
                placeholder="BE XXXX.XXX.XXX"
                label={_("business_number")}
                info={_("uen_info")}
                error={errors.uen}
                value={uen}
                autoComplete="none"
                // className="ttp-input-lg"
                labelClassName="ttp-label-lg"
                onKeyUp={(e) => handleUenKeyUp(e.target.value)}
                //   handleBlur={(e) => handleUenBlur(e.target.value)}
                onChange={(e) => setUen(e.target.value)}
              ></FormInput>

              <FormInput
                name="organization"
                required={true}
                label={_("organization")}
                autoComplete="off"
                error={errors.organization}
                value={organization}
                // className="ttp-input-lg"
                labelClassName="ttp-label-lg"
                onChange={(e) => setOrganization(e.target.value)}
              />
              <div className={styles.vatbox}>
                <div className={styles.acceptActions}>
                  <Checkbox
                    checked={subjectToVAT}
                    onClick={handleVatClick}
                    radio
                  />
                  <label className={styles.checkbox} onClick={handleVatClick}>
                    {_("subject_to_vat")}
                  </label>
                </div>
                <div className={styles.acceptActions}>
                  <Checkbox
                    checked={!subjectToVAT}
                    onClick={handleVatClick}
                    radio={true}
                  />
                  <label className={styles.checkbox} onClick={handleVatClick}>
                    {_("not_subject_to_vat")}
                  </label>
                </div>
              </div>
            </div>
            <div className="cell small-12 medium-6">
              <div className="ttp-form-group-h">
                <label className="ttp-label">
                  {_("address")}
                  <span className="star">*</span>
                </label>
                <PlacesAutocomplete
                  value={address}
                  onChange={handleAddressChange}
                  onSelect={handleSelectAddress}
                  searchOptions={{
                    componentRestrictions: { country: ["be"] },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="group-input">
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: classnames(
                            "location-search-input",
                            errors.address ? "error" : ""
                          ),
                        })}
                        autoComplete="none"
                        name="address"
                        // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                      />
                      <span className="ttp-error">{errors.address}</span>
                      {suggestions.length > 0 ? (
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <FormInput
                name="postalCode"
                required={true}
                label={_("zipCode")}
                autoComplete="off"
                error={errors.postalCode}
                value={postalCode}
                // className="ttp-input-lg"
                labelClassName="ttp-label-lg"
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <FormInput
                name="city"
                required={true}
                label={_("city")}
                autoComplete="off"
                error={errors.city}
                value={city}
                // className="ttp-input-lg"
                labelClassName="ttp-label-lg"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.modalActions}>
            <Button variant="default" onClick={handleCloseModal}>
              {_("cancel")}
            </Button>
            <Button onClick={saveStep1}>{"Envoyer le bon de commande"}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import Tippy from "@tippyjs/react";
import { useQuery } from "react-query";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import styles from "../Portals.module.scss";
import Switch from "../../../../common/Switch/Switch";
import _ from "../../../../../i18n";
import {
  getFolderSpecialties,
  getMediasByID,
  getServices,
  getSpecialties,
  getTeam,
} from "../../../../../api";
import AddMedia from "../../../../common/AddMedia";

import {
  TTP_API_URL,
  TTP_ONBOARDING_URL,
  TTP_MASTER_CHAIN_URL,
} from "../../../../../config";
import IconCopy from "../../../../common/icons/IconCopy";
import IconDone from "../../../../common/icons/IconDone";
import IconInfo from "../../../../common/icons/IconInfo";
import { getFaqs } from "../../../../../api/faq";
import { getTestimonials } from "../../../../../api/testimonial";
import MemberV3 from "./Designs/MemberV3";
import MemberV2 from "./Designs/MemberV2";
import MemberV1 from "./Designs/MemberV1";
import Custom from "./Designs/Custom";
import Classic from "./Designs/Classic";
import { message } from "antd";
import Button from "../../../../common/Button";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

const days = ["MON", "TUE", "WED", "TUR", "FRI", "SAT", "SUN"];

const formulaSettings = {
  LITE_FREE: {
    classicDesign: {
      available: true,
      specialties: {
        maxPicks: 3,
        maxFavorites: 0,
        allowFavorites: false,
      },
      multilingual: false,
    },
    personalizedDesign: {
      available: false,
    },
  },
  LITE_EXTRA: {
    classicDesign: {
      available: true,
      specialties: {
        maxPicks: -1,
        maxFavorites: 0,
        allowFavorites: false,
      },
      news: {
        type: ["FFF"],
        period: "MONTH",
        nbResults: -1,
      },
      multilingual: true,
    },
    personalizedDesign: {
      available: true,
      specialties: {
        maxPicks: -1,
        maxFavorites: 3,
        allowFavorites: true,
      },
      news: {
        type: ["FFF"],
        period: "MONTH",
        nbResults: -1,
      },
      services: {
        maxPicks: 3,
      },
      faq: {
        maxPicks: 5,
      },
      testimonials: {
        maxPicks: 3,
      },
      multilingual: true,
    },
  },
  ALLOW_ALL: {
    classicDesign: {
      available: true,
      specialties: {
        maxPicks: -1,
        maxFavorites: 3,
        allowFavorites: true,
      },
      news: {
        type: ["FFF", "MINE"],
        period: "ALL",
        nbResults: -1,
      },
      multilingual: true,
    },
    personalizedDesign: {
      available: true,
      specialties: {
        maxPicks: -1,
        maxFavorites: 3,
        allowFavorites: true,
      },
      news: {
        type: ["FFF", "MINE"],
        period: "ALL",
        nbResults: -1,
      },
      services: {
        maxPicks: -1,
      },
      faq: {
        maxPicks: -1,
      },
      testimonials: {
        maxPicks: -1,
      },
      multilingual: true,
    },
  },
};

const orderMemberSettings = {
  v1: {
    media: {
      maxPick: 3,
    },
  },
  v2: {
    media: {
      maxPick: 5,
    },
    partners: { maxPick: 2 },

    services: {
      maxPick: 3,
    },
    stats: { maxPick: 2 },
    specialties: {
      maxPick: 3,
      maxFavorites: 1,
      allowFavorites: true,
    },
    testimonials: {
      maxPick: 2,
    },
    faq: {
      maxPick: 3,
    },
  },
  v3: {
    media: {
      maxPick: -1,
    },
    partners: { maxPick: 6 },
    services: {
      maxPick: -1,
    },
    stats: { maxPick: 4 },
    specialties: {
      maxPick: -1,
      maxFavorites: -1,
      allowFavorites: true,
    },
    testimonials: {
      maxPick: 10,
    },
    faq: {
      maxPick: 10,
    },
  },
};

const versionsList = ["v1", "v2", "v3"];

const Public = ({
  fiduciarySettings,
  portals,
  setPortals,
  handleCheckPortal,
  setIsLoading,
  setPortalsData,
  folderSpecialties,
  setFolderSpecialties,
  subdomain,
  setSubdomain,
  subdomainError,
  subdomainSuggestion,
  setSubdomainError,
  setSubdomainSuggestion,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
}) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const [medias, setMedias] = useState([]);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [dbCollaborators, setDbCollaborators] = useState([]);
  const [copied, setCopied] = useState(false);
  const [services, setServices] = useState([]);
  const [dbServices, setDbServices] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [dbFaqs, setDbFaqs] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [dbTestimonials, setDbTestimonials] = useState([]);
  const [fiduciaryConfig, setFiduciaryConfig] = useState(
    formulaSettings["ALLOW_ALL"]
  );
  const [memberMedias, setMemberMedias] = useState([]);
  const [aboutImage, setAboutImage] = useState(null);
  const [singleServiceImage, setSingleServiceImage] = useState(null);
  const [partners, setPartners] = useState([]);
  const [numbersList, setNumbersList] = useState([]);
  const [numbersImage, setNumbersImage] = useState(null);
  const [valuesList, setValuesList] = useState([]);
  const [valuesVideo, setValuesVideo] = useState(null);
  const [galleryMedias, setGalleryMedias] = useState([]);
  const [packsList, setPacksList] = useState([]);
  const [featuresCategoriesList, setFeaturesCategoriesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const titleAttr = (language) => {
    return `title${language.charAt(0).toUpperCase() + language.slice(1)}`;
  };

  const descriptionAttr = (language) => {
    return `description${language.charAt(0).toUpperCase() + language.slice(1)}`;
  };

  const fetchMedias = async (ids, callBack) => {
    try {
      if (auth.token && fiduciary) {
        const response = await getMediasByID({
          token: auth.token,
          ids,
        });
        if (response) {
          callBack(
            response.data.data
              .map((item) => {
                if (item.id) {
                  return { ...item, checked: true };
                }
              })
              .filter(Boolean)
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSingleImage = async (id, callBack) => {
    try {
      if (auth.token && fiduciary) {
        const response = await getMediasByID({
          token: auth.token,
          ids: [id],
        });

        return callBack(response.data.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMemberMedias = async (list) => {
    try {
      if (auth.token && fiduciary && memberMedias.length === 0) {
        const ids = list.map((el) => el.id);
        const response = await getMediasByID({
          token: auth.token,
          ids,
        });
        const images = response.data.data;
        if (images) {
          setMemberMedias(
            list
              .map((item) => {
                if (images.filter((el) => el.id === item.id).length === 1) {
                  return {
                    ...item,
                    id: uuidv4(),
                    image: images.filter((el) => el.id === item.id)[0],
                    show: true,
                  };
                }
              })
              .filter(Boolean)
          );
        }
        return response.data.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCollaborators = async () => {
    if (auth.token && fiduciary && collaborators.length === 0) {
      const response = await getTeam({
        token: auth.token,
        communityId: fiduciary.id,
        ids: [],
        limit: 200,
      });
      if (response) {
        setDbCollaborators(
          response.data.data.map((item) => {
            item.enableAvatar = true;
            return item;
          })
        );
      }
      return response.data.data;
    }
  };

  let { data: specialties } = useQuery(
    ["getSpecialties", auth.token, fiduciary],
    async () => {
      try {
        if (auth.token && fiduciary) {
          const response = await getSpecialties({
            token: auth.token,
          });
          if (response.data.data.filter((el) => el.isActivated).length === 0)
            return null;
          return response.data.data;
        }
      } catch (error) {}
    }
  );

  const fetchFolderSpecialties = async () => {
    if (auth.token && fiduciary) {
      try {
        const response = await getFolderSpecialties({
          token: auth.token,
          communityId: fiduciary.id,
        });

        if (response && response.data.data[0] && !folderSpecialties) {
          const list =
            response.data.data[0] && response.data.data[0].specialties
              ? response.data.data[0].specialties
                  .split(",")
                  .map((el) => parseInt(el))
              : [];
          const favorites =
            response.data.data[0] && response.data.data[0].favorites
              ? response.data.data[0].favorites
                  .split(",")
                  .map((el) => parseInt(el))
              : [];

          setFolderSpecialties({ list, favorites });
        }
      } catch (error) {
        setFolderSpecialties({ list: [], favorites: [] });
      }
    }
  };

  const fetchServices = async () => {
    if (auth.token && fiduciary) {
      try {
        const res = await getServices(auth.token, fiduciary.id);
        setDbServices(
          res.data.data.map((el) => {
            return { ...el, show: true };
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchFaqs = async () => {
    if (auth.token && fiduciary) {
      try {
        const res = await getFaqs(auth.token, fiduciary.id);
        setDbFaqs(
          res.data.data.map((el) => {
            return { ...el, show: true };
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchTestimonials = async () => {
    if (auth.token && fiduciary) {
      try {
        const res = await getTestimonials(auth.token, fiduciary.id);
        setDbTestimonials(
          res.data.data.map((el) => {
            return { ...el, show: true };
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (fiduciary) {
      fetchCollaborators();
      fetchFolderSpecialties();
      fetchServices();
      fetchFaqs();
      fetchTestimonials();
      let tmpConfig = fiduciary.newsletterPreferences
        ? ["LITE_FREE", "LITE_EXTRA"].includes(
            fiduciary.newsletterPreferences.apps[0].config
          )
          ? fiduciary.newsletterPreferences.apps[0].config
          : "ALLOW_ALL"
        : "ALLOW_ALL";
      setFiduciaryConfig(formulaSettings[tmpConfig]);
    }
  }, [fiduciary]);

  useEffect(() => {
    const name = fiduciary.abbreviation
      ? fiduciary.abbreviation
      : fiduciary.name;
    if (fiduciarySettings) {
      let data = fiduciarySettings;
      const news = {
        ...data.public.config.news,
        relevance: data.public.config.news.relevance
          ? data.public.config.news.relevance
          : 3,
        type:
          data.public.config.news.type &&
          ["MINE", "FFF", "BOTH"].includes(data.public.config.news.type)
            ? data.public.config.news.type
            : "BOTH",
        titleFr: data.public.config.news.titleFr
          ? data.public.config.news.titleFr
          : `Publiés par ${name}`,
        titleEn: data.public.config.news.titleEn
          ? data.public.config.news.titleEn
          : `Published by ${name}`,
        titleNl: data.public.config.news.titleNl
          ? data.public.config.news.titleNl
          : `Gepubliceerd door ${name}`,
        descriptionFr: data.public.config.news.descriptionFr
          ? data.public.config.news.descriptionFr
          : `Suivez toute l'actualité de ${name}, et ne ratez aucune information en suivant nos derniers articles`,
        descriptionEn: data.public.config.news.descriptionEn
          ? data.public.config.news.descriptionEn
          : `Follow all the news of ${name}, and do not miss any information by following our latest articles`,
        descriptionNl: data.public.config.news.descriptionNl
          ? data.public.config.news.descriptionNl
          : `Volg al het nieuws van ${name} en mis geen enkele informatie door onze nieuwste artikelen te volgen`,
      };

      const team = {
        ...data.public.config.team,
        list: data.public.config.team.list ? data.public.config.team.list : [],
        titleFr: data.public.config.team.titleFr
          ? data.public.config.team.titleFr
          : `Collaborateurs ${name}`,
        titleEn: data.public.config.team.titleEn
          ? data.public.config.team.titleEn
          : `Collaborators ${name}`,
        titleNl: data.public.config.team.titleNl
          ? data.public.config.team.titleNl
          : `Bijdragers ${name}`,
        descriptionFr: data.public.config.team.descriptionFr
          ? data.public.config.team.descriptionFr
          : `Découvrez l'équipe. Le cabinet est composée d'une équipe compétente et entièrement dédiée aux besoins de ses clients`,
        descriptionEn: data.public.config.team.descriptionEn
          ? data.public.config.team.descriptionEn
          : `Discover the team. The firm is made up of a competent team entirely dedicated to the needs of its clients`,
        descriptionNl: data.public.config.team.descriptionNl
          ? data.public.config.team.descriptionNl
          : `Ontdek het team. Het kantoor bestaat uit een competent team dat volledig toegewijd is aan de behoeften van zijn klanten`,
      };
      let specialty = portals.public.config.specialty;
      if (data.public.config.specialty) {
        specialty = {
          ...portals.public.config.specialty,
          activated: data.public.config.specialty.activated
            ? data.public.config.specialty.activated
            : false,
          titleFr: data.public.config.specialty.titleFr
            ? data.public.config.specialty.titleFr
            : ``,
          titleEn: data.public.config.specialty.titleEn
            ? data.public.config.specialty.titleEn
            : ``,
          titleNl: data.public.config.specialty.titleNl
            ? data.public.config.specialty.titleNl
            : ``,
          descriptionFr: data.public.config.specialty.descriptionFr
            ? data.public.config.specialty.descriptionFr
            : ``,
          descriptionEn: data.public.config.specialty.descriptionEn
            ? data.public.config.specialty.descriptionEn
            : ``,
          descriptionNl: data.public.config.specialty.descriptionNl
            ? data.public.config.specialty.descriptionNl
            : ``,
        };
      }
      let media = portals.public.config.media;
      if (data.public.config.media) {
        media = {
          ...portals.public.config.media,
          activated: data.public.config.media.activated
            ? data.public.config.media.activated
            : false,
          descriptionFr: data.public.config.media.descriptionFr
            ? data.public.config.media.descriptionFr
            : ``,
          descriptionEn: data.public.config.media.descriptionEn
            ? data.public.config.media.descriptionEn
            : ``,
          descriptionNl: data.public.config.media.descriptionNl
            ? data.public.config.media.descriptionNl
            : ``,
        };
      }

      const newsletter = {
        activated: data.public.config.newsletter?.activated
          ? data.public.config.newsletter.activated
          : false,
        descriptionFr: data.public.config.newsletter?.descriptionFr
          ? data.public.config.newsletter.descriptionFr
          : ``,
        descriptionEn: data.public.config.newsletter?.descriptionEn
          ? data.public.config.newsletter.descriptionEn
          : ``,
        descriptionNl: data.public.config.newsletter?.descriptionNl
          ? data.public.config.newsletter.descriptionNl
          : ``,
        titleFr: data.public.config.newsletter?.titleFr
          ? data.public.config.newsletter.titleFr
          : ``,
        titleEn: data.public.config.newsletter?.titleEn
          ? data.public.config.newsletter.titleEn
          : ``,
        titleNl: data.public.config.newsletter?.titleNl
          ? data.public.config.newsletter.titleNl
          : ``,
        messageFr: data.public.config.newsletter?.messageFr
          ? data.public.config.newsletter.messageFr
          : ``,
        messageEn: data.public.config.newsletter?.messageEn
          ? data.public.config.newsletter.messageEn
          : ``,
        MessageNl: data.public.config.newsletter?.MessageNl
          ? data.public.config.newsletter.MessageNl
          : ``,
      };

      let memberMedia = portals.public.config.memberMedia;
      if (data.public.config.memberMedia) {
        memberMedia = {
          ...portals.public.config.memberMedia,
          activated: data.public.config.memberMedia.activated
            ? data.public.config.memberMedia.activated
            : false,
        };
      }

      let about = portals.public.config.about;
      if (data.public.config.about) {
        about = {
          ...portals.public.config.about,
          activated: data.public.config.about.activated
            ? data.public.config.about.activated
            : false,
          descriptionFr: data.public.config.about.descriptionFr
            ? data.public.config.about.descriptionFr
            : ``,
          descriptionEn: data.public.config.about.descriptionEn
            ? data.public.config.about.descriptionEn
            : ``,
          descriptionNl: data.public.config.about.descriptionNl
            ? data.public.config.about.descriptionNl
            : ``,
          titleFr: data.public.config.about.titleFr
            ? data.public.config.about.titleFr
            : ``,
          titleEn: data.public.config.about.titleEn
            ? data.public.config.about.titleEn
            : ``,
          titleNl: data.public.config.about.titleNl
            ? data.public.config.about.titleNl
            : ``,
          aboutPage: {
            ...data.public.config.about.aboutPage,
            banner: {
              ...portals.public.config.about.aboutPage.banner,
              descriptionFr: data?.public?.config?.about?.aboutPage?.banner
                ?.descriptionFr
                ? data?.public?.config?.about?.aboutPage?.banner?.descriptionFr
                : ``,
              descriptionEn: data?.public?.config?.about?.aboutPage?.banner
                ?.descriptionEn
                ? data?.public?.config?.about?.aboutPage?.banner?.descriptionEn
                : ``,
              descriptionNl: data?.public?.config?.about?.aboutPage?.banner
                ?.descriptionNl
                ? data?.public?.config?.about?.aboutPage?.banner?.descriptionNl
                : ``,
              titleFr: data?.public?.config?.about?.aboutPage?.banner?.titleFr
                ? data?.public?.config?.about?.aboutPage?.banner?.titleFr
                : ``,
              titleEn: data?.public?.config?.about?.aboutPage?.banner?.titleEn
                ? data?.public?.config?.about?.aboutPage?.banner?.titleEn
                : ``,
              titleNl: data?.public?.config?.about?.aboutPage?.banner?.titleNl
                ? data?.public?.config?.about?.aboutPage?.banner?.titleNl
                : ``,
            },
            history: {
              ...portals.public.config.about.aboutPage.history,
              activated: data?.public?.config?.about?.aboutPage?.history
                ?.activated
                ? data?.public?.config?.about?.aboutPage?.history?.activated
                : false,
              descriptionFr: data?.public?.config?.about?.aboutPage?.history
                ?.descriptionFr
                ? data?.public?.config?.about?.aboutPage?.history?.descriptionFr
                : ``,
              descriptionEn: data?.public?.config?.about?.aboutPage?.history
                ?.descriptionEn
                ? data?.public?.config?.about?.aboutPage?.history?.descriptionEn
                : ``,
              descriptionNl: data?.public?.config?.about?.aboutPage?.history
                ?.descriptionNl
                ? data?.public?.config?.about?.aboutPage?.history?.descriptionNl
                : ``,
              titleFr: data?.public?.config?.about?.aboutPage?.history?.titleFr
                ? data?.public?.config?.about?.aboutPage?.history?.titleFr
                : ``,
              titleEn: data?.public?.config?.about?.aboutPage?.history?.titleEn
                ? data?.public?.config?.about?.aboutPage?.history?.titleEn
                : ``,
              titleNl: data?.public?.config?.about?.aboutPage?.history?.titleNl
                ? data?.public?.config?.about?.aboutPage?.history?.titleNl
                : ``,
            },
            values: {
              ...portals.public.config.about.aboutPage.values,
              activated: data?.public?.config?.about?.aboutPage?.values
                ?.activated
                ? data?.public?.config?.about?.aboutPage?.values?.activated
                : false,
              descriptionFr: data?.public?.config?.about?.aboutPage?.values
                ?.descriptionFr
                ? data?.public?.config?.about?.aboutPage?.values?.descriptionFr
                : ``,
              descriptionEn: data?.public?.config?.about?.aboutPage?.values
                ?.descriptionEn
                ? data?.public?.config?.about?.aboutPage?.values?.descriptionEn
                : ``,
              descriptionNl: data?.public?.config?.about?.aboutPage?.values
                ?.descriptionNl
                ? data?.public?.config?.about?.aboutPage?.values?.descriptionNl
                : ``,
              titleFr: data?.public?.config?.about?.aboutPage?.values?.titleFr
                ? data?.public?.config?.about?.aboutPage?.values?.titleFr
                : ``,
              titleEn: data?.public?.config?.about?.aboutPage?.values?.titleEn
                ? data?.public?.config?.about?.aboutPage?.values?.titleEn
                : ``,
              titleNl: data?.public?.config?.about?.aboutPage?.values?.titleNl
                ? data?.public?.config?.about?.aboutPage?.values?.titleNl
                : ``,
            },
            gallery: {
              ...portals.public.config.about.aboutPage.gallery,
              activated: data?.public?.config?.about?.aboutPage?.gallery
                ?.activated
                ? data?.public?.config?.about?.aboutPage?.gallery?.activated
                : false,
              descriptionFr: data?.public?.config?.about?.aboutPage?.gallery
                ?.descriptionFr
                ? data?.public?.config?.about?.aboutPage?.gallery?.descriptionFr
                : ``,
              descriptionEn: data?.public?.config?.about?.aboutPage?.gallery
                ?.descriptionEn
                ? data?.public?.config?.about?.aboutPage?.gallery?.descriptionEn
                : ``,
              descriptionNl: data?.public?.config?.about?.aboutPage?.gallery
                ?.descriptionNl
                ? data?.public?.config?.about?.aboutPage?.gallery?.descriptionNl
                : ``,
              titleFr: data?.public?.config?.about?.aboutPage?.gallery?.titleFr
                ? data?.public?.config?.about?.aboutPage?.gallery?.titleFr
                : ``,
              titleEn: data?.public?.config?.about?.aboutPage?.gallery?.titleEn
                ? data?.public?.config?.about?.aboutPage?.gallery?.titleEn
                : ``,
              titleNl: data?.public?.config?.about?.aboutPage?.gallery?.titleNl
                ? data?.public?.config?.about?.aboutPage?.gallery?.titleNl
                : ``,
            },
            // orderedKeys: data.public.config.about.aboutPage.orderedKeys,
          },
        };

        if (data?.public?.config?.values?.list) {
          setValuesList(data.public.config.values.list);
        }

        if (data?.public?.config?.values?.video) {
          setValuesVideo(data.public.config.values.video);
        }
      }

      let singleService = portals.public.config.singleService;
      if (data.public.config.singleService) {
        singleService = {
          ...portals.public.config.singleService,
          activated: data.public.config.singleService.activated
            ? data.public.config.singleService.activated
            : false,
          descriptionFr: data.public.config.singleService.descriptionFr
            ? data.public.config.singleService.descriptionFr
            : ``,
          descriptionEn: data.public.config.singleService.descriptionEn
            ? data.public.config.singleService.descriptionEn
            : ``,
          descriptionNl: data.public.config.singleService.descriptionNl
            ? data.public.config.singleService.descriptionNl
            : ``,
          titleFr: data.public.config.singleService.titleFr
            ? data.public.config.singleService.titleFr
            : ``,
          titleEn: data.public.config.singleService.titleEn
            ? data.public.config.singleService.titleEn
            : ``,
          titleNl: data.public.config.singleService.titleNl
            ? data.public.config.singleService.titleNl
            : ``,
        };
      }
      let partners = portals.public.config.partners;
      if (data.public.config.partners) {
        partners = {
          ...portals.public.config.partners,
          activated: data.public.config.partners.activated
            ? data.public.config.partners.activated
            : false,
          titleFr: data.public.config.partners.titleFr
            ? data.public.config.partners.titleFr
            : ``,
          titleEn: data.public.config.partners.titleEn
            ? data.public.config.partners.titleEn
            : ``,
          titleNl: data.public.config.partners.titleNl
            ? data.public.config.partners.titleNl
            : ``,
          list: data.public.config.partners.list
            ? data.public.config.partners.list
            : [],
        };
        if (data.public.config.partners.list) {
          setPartners(data.public.config.partners.list);
        }
      }

      let numbers = portals.public.config.numbers;
      if (data.public.config.numbers) {
        numbers = {
          ...portals.public.config.numbers,
          activated: data.public.config.numbers.activated
            ? data.public.config.numbers.activated
            : false,
          titleFr: data.public.config.numbers.titleFr
            ? data.public.config.numbers.titleFr
            : ``,
          titleEn: data.public.config.numbers.titleEn
            ? data.public.config.numbers.titleEn
            : ``,
          titleNl: data.public.config.numbers.titleNl
            ? data.public.config.numbers.titleNl
            : ``,
          descriptionFr: data.public.config.numbers.descriptionFr
            ? data.public.config.numbers.descriptionFr
            : ``,
          descriptionEn: data.public.config.numbers.descriptionEn
            ? data.public.config.numbers.descriptionEn
            : ``,
          descriptionNl: data.public.config.numbers.descriptionNl
            ? data.public.config.numbers.descriptionNl
            : ``,
          list: data.public.config.numbers.list
            ? data.public.config.numbers.list
            : [],
        };
        if (data.public.config.numbers.list) {
          setNumbersList(data.public.config.numbers.list);
        }
      }
      let packs = portals.public.config.packs;
      if (data.public.config.packs) {
        packs = {
          ...portals.public.config.packs,
          activated: data.public.config.numbers.activated
            ? data.public.config.numbers.activated
            : false,
          list: data.public.config.packs.list ?? [],
          featuresCategories: data.public.config.packs.featuresCategories ?? [],
        };
        if (data.public.config.packs.list) {
          setPacksList(data.public.config.packs.list);
        }
        if (data.public.config.packs.featuresCategories) {
          setFeaturesCategoriesList(
            data.public.config.packs.featuresCategories
          );
        }
      }

      setPortals({
        ...data,
        public: {
          ...data.public,
          enabled: fiduciary.id === 1256 ? true : data.public.enabled,
          // customDesign: fiduciaryConfig.personalizedDesign.available
          //   ? data.public.customDesign
          //     ? true
          //     : false
          //   : false,
          // orderMemberDesign: data.public.orderMemberDesign
          //   ? data.public.orderMemberDesign
          //   : false,
          customDesign: false,
          orderMemberDesign: true,
          orderMemberDesignVersion: data.public.orderMemberDesignVersion
            ? data.public.orderMemberDesignVersion
            : "v1",
          config: {
            ...data.public.config,
            packs,
            memberMedia,
            partners,
            numbers,
            about,
            singleService,
            media,
            specialty,
            news,
            team,
            newsletter,
            services: data.public.config.services
              ? data.public.config.services
              : portals.public.config.services,
            faqs: data.public.config.faqs
              ? data.public.config.faqs
              : portals.public.config.faqs,
            testimonials: data.public.config.testimonials
              ? data.public.config.testimonials
              : portals.public.config.testimonials,
            // packsPageOrder: data.public.config.packsPageOrder ?? null,
            // aboutPageOrder: data.public.config.aboutPageOrder ?? null,
          },
        },
      });
      if (
        data.public.config.media.list &&
        data.public.config.media.list.length > 0
      ) {
        fetchMedias(data.public.config.media.list, setMedias);
      }
      if (
        data?.public?.config?.gallery?.list &&
        data?.public?.config?.gallery?.list?.length > 0
      ) {
        fetchMedias(data.public.config.gallery.list, setGalleryMedias);
      }
      if (
        data.public.config.memberMedia &&
        data.public.config.memberMedia.list &&
        data.public.config.memberMedia.list.length > 0
      ) {
        fetchMemberMedias(data.public.config.memberMedia.list);
      }
      if (data.public.config.about && data.public.config.about.image) {
        fetchSingleImage(data.public.config.about.image, setAboutImage);
      }
      if (data.public.config.numbers && data.public.config.numbers.image) {
        fetchSingleImage(data.public.config.numbers.image, setNumbersImage);
      }
      if (
        data.public.config.singleService &&
        data.public.config.singleService.image
      ) {
        fetchSingleImage(
          data.public.config.singleService.image,
          setSingleServiceImage
        );
      }

      if (
        services.length > 0 &&
        data.public.config.services &&
        data.public.config.services.list &&
        data.public.config.services.list.length > 0
      ) {
        let tmpShow = [];
        data.public.config.services.list.forEach((el) => {
          const service = services.filter((ser) => ser.id === el)[0];
          if (service) tmpShow.push(service);
        });

        setServices([
          ...tmpShow,
          ...services
            .filter((el) => !data.public.config.services.list.includes(el.id))
            .map((el) => {
              return { ...el, show: false };
            }),
        ]);
      }
      if (
        faqs.length > 0 &&
        data.public.config.faqs &&
        data.public.config.faqs.list &&
        data.public.config.faqs.list.length > 0
      ) {
        let tmpShow = [];
        data.public.config.faqs.list.forEach((el) => {
          const faq = faqs.filter((ser) => ser.id === el)[0];
          if (faq) tmpShow.push(faq);
        });

        setFaqs([
          ...tmpShow,
          ...faqs
            .filter((el) => !data.public.config.faqs.list.includes(el.id))
            .map((el) => {
              return { ...el, show: false };
            }),
        ]);
      }
      if (
        testimonials.length > 0 &&
        data.public.config.testimonials &&
        data.public.config.testimonials.list &&
        data.public.config.testimonials.list.length > 0
      ) {
        let tmpShow = [];
        data.public.config.testimonials.list.forEach((el) => {
          const testimonial = testimonials.filter((ser) => ser.id === el)[0];
          if (testimonial) tmpShow.push(testimonial);
        });

        setTestimonials([
          ...tmpShow,
          ...testimonials
            .filter(
              (el) => !data.public.config.testimonials.list.includes(el.id)
            )
            .map((el) => {
              return { ...el, show: false };
            }),
        ]);
      }

      setIsLoading(false);
    } else {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          customDesign: fiduciaryConfig.personalizedDesign.available
            ? portals.public.customDesign
              ? true
              : false
            : false,
          config: {
            ...portals.public.config,
            news: {
              ...portals.public.config.news,
              titleFr: `Publiés par ${name}`,
              titleEn: `Published by ${name}`,
              titleNl: `Gepubliceerd door ${name}`,
              descriptionFr: `Suivez toute l'actualité de ${name}, et ne ratez aucune information en suivant nos derniers articles`,
              descriptionEn: `Follow all the news of ${name}, and do not miss any information by following our latest articles`,
              descriptionNl: `Volg al het nieuws van ${name} en mis geen enkele informatie door onze nieuwste artikelen te volgen`,
            },
            team: {
              ...portals.public.config.team,
              titleFr: `Collaborateurs ${name}`,
              titleEn: `Collaborators ${name}`,
              titleNl: `Bijdragers ${name}`,
              descriptionFr: `Découvrez l'équipe. Le cabinet est composée d'une équipe compétente et entièrement dédiée aux besoins de ses clients`,
              descriptionEn: `Discover the team. The firm is made up of a competent team entirely dedicated to the needs of its clients`,
              descriptionNl: `Ontdek het team. Het kantoor bestaat uit een competent team dat volledig toegewijd is aan de behoeften van zijn klanten`,
            },
          },
        },
      });
      setIsLoading(false);
    }
  }, [fiduciarySettings, fiduciaryConfig]);

  useEffect(() => {
    let dataPublic = JSON.parse(JSON.stringify(portals.public));
    dataPublic.config.team.list = collaborators
      .map((item) => {
        if (item.enableAvatar) return item.id;
      })
      .filter(Boolean);

    dataPublic.config.media.list = medias
      .map((item) => {
        if (item.checked) return item.id;
      })
      .filter(Boolean);

    dataPublic.config.memberMedia.list = memberMedias.map((item) => {
      return {
        id: item.image.id,
        titleFr: item.titleFr,
        titleEn: item.titleEn,
        titleNl: item.titleNl,
        descriptionFr: item.descriptionFr,
        descriptionEn: item.descriptionEn,
        descriptionNl: item.descriptionNl,
      };
    });

    dataPublic.config.services.list = services
      .map((item) => {
        if (item.show) return item.id;
      })
      .filter(Boolean);

    dataPublic.config.faqs.list = faqs
      .map((item) => {
        if (item.show) return item.id;
      })
      .filter(Boolean);

    dataPublic.config.testimonials.list = testimonials
      .map((item) => {
        if (item.show) return item.id;
      })
      .filter(Boolean);

    dataPublic.config.about.image = aboutImage ? aboutImage.id : null;
    dataPublic.config.singleService.image = singleServiceImage
      ? singleServiceImage.id
      : null;

    dataPublic.config.partners.list = partners;
    dataPublic.config.numbers.list = numbersList;
    dataPublic.config.numbers.image = numbersImage ? numbersImage.id : null;

    if (!dataPublic.config.values) {
      dataPublic.config.values = {};
    }
    if (!dataPublic.config.gallery) {
      dataPublic.config.gallery = {};
    }
    dataPublic.config.values.list = valuesList;
    dataPublic.config.values.video = valuesVideo;
    dataPublic.config.gallery.list = galleryMedias.map((el) => el.id);

    dataPublic.config.packs.list = packsList;
    dataPublic.config.packs.featuresCategories = featuresCategoriesList;

    setPortalsData(dataPublic);
  }, [
    portals,
    medias,
    collaborators,
    services,
    faqs,
    testimonials,
    memberMedias,
    aboutImage,
    singleServiceImage,
    partners,
    numbersImage,
    numbersList,
    valuesList,
    valuesVideo,
    galleryMedias,
    packsList,
    featuresCategoriesList,
  ]);

  const handlePortalBlocReorder = (value, key) => {
    const levels = key.split("|");
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          [levels[0]]: {
            ...portals.public.config[levels[0]],
            [levels[1]]: value,
          },
        },
      },
    });
  };

  const handlePortalBlocChange = (value, key) => {
    const levels = key.split("|");
    if (levels.length === 3) {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [levels[0]]: {
              ...portals.public.config[levels[0]],
              [levels[1]]: {
                ...portals.public.config[levels[0]][levels[1]],
                [levels[2]]: {
                  ...portals.public.config[levels[0]][levels[1]][levels[2]],
                  activated: value,
                },
              },
            },
          },
        },
      });
    } else if (levels.length === 2) {
      console.log(key, value);
      console.log(key, value);
      const levelOne = portals.public.config[levels[0]] ?? {};
      console.log({
        ...portals.public.config,
        [levels[0]]: {
          ...levelOne,
          [levels[1]]: value,
        },
      });
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [levels[0]]: {
              ...levelOne,
              [levels[1]]: value,
            },
          },
        },
      });
    } else {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [key]: {
              ...portals.public.config[key],
              activated: value,
            },
          },
        },
      });
    }
  };

  const handlePortalBlocTitleChange = (language, value, key) => {
    const levels = key.split("|");
    if (levels.length === 2) {
      const levelOne = portals.public.config[levels[0]] ?? {};
      const levelTwo =
        portals.public.config[levels[0]] &&
        portals.public.config[levels[0]][levels[1]]
          ? portals.public.config[levels[0]][levels[1]]
          : {};
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [levels[0]]: {
              ...levelOne,
              [levels[1]]: {
                ...levelTwo,
                [titleAttr(language)]: value,
              },
            },
          },
        },
      });
    } else {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [key]: {
              ...portals.public.config[key],
              [titleAttr(language)]: value,
            },
          },
        },
      });
    }
  };

  const handleGeoChange = (e, field) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          contact: {
            ...portals.public.config.contact,
            [field]: e.target.value,
          },
        },
      },
    });
  };

  const handlePortalBlocDescriptionChange = (language, value, key) => {
    const levels = key.split("|");
    if (levels.length === 2) {
      const levelOne = portals.public.config[levels[0]] ?? {};
      const levelTwo =
        portals.public.config[levels[0]] &&
        portals.public.config[levels[0]][levels[1]]
          ? portals.public.config[levels[0]][levels[1]]
          : {};
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [levels[0]]: {
              ...levelOne,
              [levels[1]]: {
                ...levelTwo,
                [descriptionAttr(language)]: value,
              },
            },
          },
        },
      });
    } else {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          config: {
            ...portals.public.config,
            [key]: {
              ...portals.public.config[key],
              [descriptionAttr(language)]: value,
            },
          },
        },
      });
    }
  };

  const sortCollaborators = ({ oldIndex, newIndex }) => {
    setCollaborators(arrayMoveImmutable(collaborators, oldIndex, newIndex));
  };

  const addPushPinOnClick = (location) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          contact: {
            ...portals.public.config.contact,
            latitude: location.latitude,
            longitude: location.longitude,
          },
        },
      },
    });
  };

  const handleChangeSchedule = (value, day, time, start) => {
    let data =
      value === "NO_WORK"
        ? portals.public.config.contact.schedule[day][time].FROM === "NO_WORK"
          ? {
              FROM: "",
              TO: "",
            }
          : {
              FROM: value,
              TO: value,
            }
        : {
            ...portals.public.config.contact.schedule[day][time],
            [start]: value,
          };

    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          contact: {
            ...portals.public.config.contact,
            schedule: {
              ...portals.public.config.contact.schedule,
              [day]: {
                ...portals.public.config.contact.schedule[day],
                [time]: data,
              },
            },
          },
        },
      },
    });
  };

  const handleApplySchedule = () => {
    const mondayData = portals.public.config.contact.schedule["MON"];
    let data = { MON: mondayData };
    days.forEach((day) => {
      if (day !== "MON") {
        data[day] = mondayData;
      }
    });
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          contact: {
            ...portals.public.config.contact,
            schedule: data,
          },
        },
      },
    });
  };

  useEffect(() => {
    const collaboratorsList = [];
    if (
      portals.public.config.team.list &&
      portals.public.config.team.list.length > 0 &&
      dbCollaborators.length > 0
    ) {
      portals.public.config.team.list.forEach((element) => {
        let ele = dbCollaborators.filter((item) => item.id === element);
        if (ele && ele.length > 0) {
          collaboratorsList.push(ele[0]);
        }
      });
      dbCollaborators.forEach((element) => {
        if (!collaboratorsList.includes(element)) {
          collaboratorsList.push({ ...element, enableAvatar: false });
        }
      });
      setCollaborators(collaboratorsList);
    } else {
      setCollaborators(dbCollaborators);
    }
  }, [portals.public.config.team.list, dbCollaborators]);

  useEffect(() => {
    if (
      portals.public.config.services.list &&
      portals.public.config.services.list.length > 0 &&
      dbServices.length > 0
    ) {
      let tmpShow = [];
      portals.public.config.services.list.forEach((el) => {
        const service = dbServices.filter((ser) => ser.id === el)[0];
        if (service) tmpShow.push(service);
      });
      setServices([
        ...tmpShow.map((el) => {
          return { ...el, show: true };
        }),
        ...dbServices
          .filter((el) => !portals.public.config.services.list.includes(el.id))
          .map((el) => {
            return { ...el, show: false };
          }),
      ]);
    } else {
      setServices(dbServices);
    }
  }, [portals.public.config.services.list, dbServices]);

  useEffect(() => {
    if (
      portals.public.config.faqs.list &&
      portals.public.config.faqs.list.length > 0 &&
      dbFaqs.length > 0
    ) {
      let tmpShow = [];
      portals.public.config.faqs.list.forEach((el) => {
        const faq = dbFaqs.filter((ser) => ser.id === el)[0];
        if (faq) tmpShow.push(faq);
      });
      setFaqs([
        ...tmpShow.map((el) => {
          return { ...el, show: true };
        }),
        ...dbFaqs
          .filter((el) => !portals.public.config.faqs.list.includes(el.id))
          .map((el) => {
            return { ...el, show: false };
          }),
      ]);
    } else {
      setFaqs(dbFaqs);
    }
  }, [portals.public.config.faqs.list, dbFaqs]);

  useEffect(() => {
    if (
      portals.public.config.testimonials.list &&
      portals.public.config.testimonials.list.length > 0 &&
      dbTestimonials.length > 0
    ) {
      let tmpShow = [];
      portals.public.config.testimonials.list.forEach((el) => {
        const testimonial = dbTestimonials.filter((ser) => ser.id === el)[0];
        if (testimonial) tmpShow.push(testimonial);
      });
      setTestimonials([
        ...tmpShow.map((el) => {
          return { ...el, show: true };
        }),
        ...dbTestimonials
          .filter(
            (el) => !portals.public.config.testimonials.list.includes(el.id)
          )
          .map((el) => {
            return { ...el, show: false };
          }),
      ]);
    } else {
      setTestimonials(dbTestimonials);
    }
  }, [portals.public.config.testimonials.list, dbTestimonials]);

  const switchDesign = (type) => {
    if (type === "classic") {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          customDesign: false,
          orderMemberDesign: false,
        },
      });
    }
    if (type === "custom") {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          customDesign: true,
          orderMemberDesign: false,
        },
      });
    }
    if (type === "member") {
      setPortals({
        ...portals,
        public: {
          ...portals.public,
          customDesign: false,
          orderMemberDesign: true,
        },
      });
    }
  };

  const switchVersion = (value) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        orderMemberDesignVersion: value,
      },
    });
  };

  const changeTheme = (value) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        designTheme: value,
      },
    });
  };

  const sortServices = ({ oldIndex, newIndex }) => {
    setServices(arrayMoveImmutable(services, oldIndex, newIndex));
  };
  const sortFaqs = ({ oldIndex, newIndex }) => {
    setFaqs(arrayMoveImmutable(faqs, oldIndex, newIndex));
  };
  const sortTestimonials = ({ oldIndex, newIndex }) => {
    setTestimonials(arrayMoveImmutable(testimonials, oldIndex, newIndex));
  };

  const handleAboutPageBlocChange = (value, key) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          about: {
            ...portals.public.config.about,
            aboutPage: {
              ...portals.public.config.about.aboutPage,
              [key]: {
                ...portals.public.config.about.aboutPage[key],
                activated: value,
              },
            },
          },
        },
      },
    });
  };

  const handleAboutPageBlocTitleChange = (language, value, key) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          about: {
            ...portals.public.config.about,
            aboutPage: {
              ...portals.public.config.about.aboutPage,
              [key]: {
                ...portals.public.config.about.aboutPage[key],
                [titleAttr(language)]: value,
              },
            },
          },
        },
      },
    });
  };

  const handleAboutPageBlocDescriptionChange = (language, value, key) => {
    setPortals({
      ...portals,
      public: {
        ...portals.public,
        config: {
          ...portals.public.config,
          about: {
            ...portals.public.config.about,
            aboutPage: {
              ...portals.public.config.about.aboutPage,
              [key]: {
                ...portals.public.config.about.aboutPage[key],
                [descriptionAttr(language)]: value,
              },
            },
          },
        },
      },
    });
  };

  const sendRequest = async () => {
    const requestUrl = `${TTP_API_URL}/mailing/send/contact-mail`;
    var formData = new FormData();
    formData.append("access_token", auth.token);
    formData.append("fromName", auth.user.lastName);
    formData.append("organization", fiduciary.id);
    formData.append("phone", auth.user.mainPhone);
    formData.append("fromEmail", auth.user.mainEmail);
    formData.append("subject", _("customization_request_confirm_title"));
    formData.append("message", "");
    formData.append("firstName", "United Associates");
    formData.append("email", "info@unitedassociates.be");
    formData.append("source", "UA_CUSTOMIZATION_DOMAIN");

    const requestMetadata = {
      method: "POST",
      body: formData,
    };
    setIsSaving(true);
    await fetch(requestUrl, requestMetadata)
      .then((res) => res.json())
      .then((res) => {
        if (res.result === "OK") {
          toast.success(_("request_sent_successfully"));
          setIsRequestSent(true);
        } else {
          toast.error(_("error"));
        }
      })
      .catch((e) => {
        toast.error(_("error"));
      })
      .finally(() => {
        setIsSaving(false);
        setIsModalOpen(false);
      });
  };

  return (
    <div className={styles.box}>
      <div className={styles.box_top}>
        <h3 className={styles.box_title}>
          <span>Portail Public</span>
          <Switch
            isChecked={portals.public.enabled}
            onChange={(e) => {
              handleCheckPortal("public", e);
            }}
          />
        </h3>
        <p>
          Ce portail vous permet de disposer d’une présence internet publique
          sans effort. Après une mini-configuration, votre fiduciaire existe
          dans un look & feel « United Associates » et les clients qui vous
          recherchent pourront retrouver des informations de base sur votre
          bureau : localisation, actualités, équipe, heures d’ouverture… L’accès
          est public, aucun login n’est nécessaire.
        </p>
      </div>
      {portals.public.enabled && (
        <div className={styles.box_content}>
          {/* {fiduciaryConfig.personalizedDesign.available && (
            <div className={styles.design_switch}>
              <div className={styles.flex}>
                <div
                  className={classNames(
                    styles.btn,
                    !portals.public.customDesign &&
                      !portals.public.orderMemberDesign
                      ? styles.btn_active
                      : ""
                  )}
                  onClick={() => switchDesign("classic")}
                >
                  {_("classic_design")}
                </div>
                <div
                  className={classNames(
                    styles.btn,
                    portals.public.customDesign ? styles.btn_active : ""
                  )}
                  onClick={() => switchDesign("custom")}
                >
                  {_("customizable_design")}
                </div>
                <div
                  className={classNames(
                    styles.btn,
                    portals.public.orderMemberDesign ? styles.btn_active : ""
                  )}
                  onClick={() => switchDesign("member")}
                >
                  {_("order_member_design")}
                </div>
              </div>
            </div>
          )} */}
          {fiduciary.id === 1256 ? (
            <div className={styles.publicBtn}>
              <a
                href={`${TTP_MASTER_CHAIN_URL}/fr/admin?token=${auth.token}`}
                target="_blank"
              >
                Configurer le portail public
              </a>
            </div>
          ) : (
            <>
              <div className={styles.themePanel}>
                <label>Theme: </label>
                <ul>
                  <li
                    onClick={() => changeTheme("defaultTheme")}
                    className={classNames(
                      styles.defaultTheme,
                      portals.public.designTheme === "defaultTheme" ||
                        !("designTheme" in portals.public)
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("redTheme")}
                    className={classNames(
                      styles.redTheme,
                      portals.public.designTheme === "redTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("purpleTheme")}
                    className={classNames(
                      styles.purpleTheme,
                      portals.public.designTheme === "purpleTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("blueTheme")}
                    className={classNames(
                      styles.blueTheme,
                      portals.public.designTheme === "blueTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("greenTheme")}
                    className={classNames(
                      styles.greenTheme,
                      portals.public.designTheme === "greenTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("orangeTheme")}
                    className={classNames(
                      styles.orangeTheme,
                      portals.public.designTheme === "orangeTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                  <li
                    onClick={() => changeTheme("yellowTheme")}
                    className={classNames(
                      styles.yellowTheme,
                      portals.public.designTheme === "yellowTheme"
                        ? styles.selected
                        : ""
                    )}
                  ></li>
                </ul>
              </div>
              {portals.public.orderMemberDesign && (
                <div className={styles.design_switch}>
                  <div className={styles.flex}>
                    {versionsList.map((ver) => (
                      <div
                        key={ver}
                        className={classNames(
                          styles.btn,
                          portals.public.orderMemberDesignVersion === ver
                            ? styles.btn_active
                            : "",
                          fiduciary.uaMode === "OECCBB_MEMBER" && ver !== "v1"
                            ? styles.btn_disabled
                            : ""
                        )}
                        onClick={() => {
                          if (fiduciary.uaMode !== "OECCBB_MEMBER") {
                            switchVersion(ver);
                          }
                        }}
                      >
                        {ver}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.portalBloc}>
                <div className={styles.public_domain}>
                  <div className={classNames(styles.mb, styles.flex)}>
                    <p className={styles.public_domain_title}>
                      {_("public_domain")}
                    </p>
                    <Tippy content={<span>{_("public_domain_rules")}</span>}>
                      <span className={styles.tooltip}>
                        <IconInfo />
                      </span>
                    </Tippy>
                  </div>
                  <div className={styles.flex}>
                    <input
                      type="text"
                      value={subdomain}
                      onChange={(e) => setSubdomain(e.target.value)}
                    />
                    <span>{"." + TTP_ONBOARDING_URL.split("//")[1]}</span>
                    <div
                      className={styles.copy}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${subdomain}.${TTP_ONBOARDING_URL.split("//")[1]}`
                          )
                          .then(() => {
                            setCopied(true);
                          });
                      }}
                    >
                      {copied ? <IconDone /> : <IconCopy />}
                    </div>
                    <span className={styles.orSpan}>{_("or")} </span>
                    {isRequestSent ? (
                      <div className={styles.requestSent}>
                        <div className={styles.icon}>
                          <i className="icon-ttp-check"></i>
                        </div>
                        <span>{_("request_sent")}</span>
                      </div>
                    ) : (
                      <div
                        className={styles.btnSendRequest}
                        onClick={() => setIsModalOpen(true)}
                      >
                        {_("customization_request")}
                      </div>
                    )}
                  </div>

                  <div className={styles.error}>{subdomainError}</div>
                  {subdomainSuggestion.length > 0 && (
                    <div className={styles.suggestion}>
                      {_("suggestion")}
                      <span
                        onClick={() => {
                          setSubdomain(subdomainSuggestion);
                          setSubdomainSuggestion("");
                          setSubdomainError("");
                        }}
                      >
                        {subdomainSuggestion}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {portals.public.orderMemberDesign &&
                portals.public.orderMemberDesignVersion === "v3" && (
                  <>
                    <MemberV3
                      handlePortalBlocChange={handlePortalBlocChange}
                      handlePortalBlocReorder={handlePortalBlocReorder}
                      portals={portals}
                      setPortals={setPortals}
                      memberMedias={memberMedias}
                      setMemberMedias={setMemberMedias}
                      orderMemberSettings={orderMemberSettings}
                      titleAttr={titleAttr}
                      handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                      partners={partners}
                      setPartners={setPartners}
                      descriptionAttr={descriptionAttr}
                      handlePortalBlocDescriptionChange={
                        handlePortalBlocDescriptionChange
                      }
                      aboutImage={aboutImage}
                      setAboutImage={setAboutImage}
                      handleAboutPageBlocChange={handleAboutPageBlocChange}
                      handleAboutPageBlocDescriptionChange={
                        handleAboutPageBlocDescriptionChange
                      }
                      handleAboutPageBlocTitleChange={
                        handleAboutPageBlocTitleChange
                      }
                      valuesList={valuesList}
                      setValuesList={setValuesList}
                      valuesVideo={valuesVideo}
                      setValuesVideo={setValuesVideo}
                      galleryMedias={galleryMedias}
                      setGalleryMedias={setGalleryMedias}
                      services={services}
                      setServices={setServices}
                      sortServices={sortServices}
                      setNumbersList={setNumbersList}
                      numbersList={numbersList}
                      numbersImage={numbersImage}
                      setNumbersImage={setNumbersImage}
                      specialties={specialties}
                      folderSpecialties={folderSpecialties}
                      setFolderSpecialties={setFolderSpecialties}
                      testimonials={testimonials}
                      setTestimonials={setTestimonials}
                      sortTestimonials={sortTestimonials}
                      faqs={faqs}
                      setFaqs={setFaqs}
                      sortFaqs={sortFaqs}
                      sortCollaborators={sortCollaborators}
                      collaborators={collaborators}
                      setCollaborators={setCollaborators}
                      handleGeoChange={handleGeoChange}
                      addPushPinOnClick={addPushPinOnClick}
                      handleChangeSchedule={handleChangeSchedule}
                      handleApplySchedule={handleApplySchedule}
                      contactEmailError={contactEmailError}
                      setContactEmailError={setContactEmailError}
                      contactPhoneError={contactPhoneError}
                      setContactPhoneError={setContactPhoneError}
                      packsList={packsList}
                      setPacksList={setPacksList}
                      featuresCategoriesList={featuresCategoriesList}
                      setFeaturesCategoriesList={setFeaturesCategoriesList}
                    />
                  </>
                )}
              {portals.public.orderMemberDesign &&
                portals.public.orderMemberDesignVersion === "v2" && (
                  <MemberV2
                    handlePortalBlocChange={handlePortalBlocChange}
                    portals={portals}
                    memberMedias={memberMedias}
                    setMemberMedias={setMemberMedias}
                    orderMemberSettings={orderMemberSettings}
                    titleAttr={titleAttr}
                    handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                    partners={partners}
                    setPartners={setPartners}
                    descriptionAttr={descriptionAttr}
                    handlePortalBlocDescriptionChange={
                      handlePortalBlocDescriptionChange
                    }
                    aboutImage={aboutImage}
                    setAboutImage={setAboutImage}
                    handleAboutPageBlocChange={handleAboutPageBlocChange}
                    handleAboutPageBlocDescriptionChange={
                      handleAboutPageBlocDescriptionChange
                    }
                    handleAboutPageBlocTitleChange={
                      handleAboutPageBlocTitleChange
                    }
                    valuesList={valuesList}
                    setValuesList={setValuesList}
                    valuesVideo={valuesVideo}
                    setValuesVideo={setValuesVideo}
                    galleryMedias={galleryMedias}
                    setGalleryMedias={setGalleryMedias}
                    services={services}
                    setServices={setServices}
                    sortServices={sortServices}
                    setNumbersList={setNumbersList}
                    numbersList={numbersList}
                    numbersImage={numbersImage}
                    setNumbersImage={setNumbersImage}
                    specialties={specialties}
                    folderSpecialties={folderSpecialties}
                    setFolderSpecialties={setFolderSpecialties}
                    testimonials={testimonials}
                    setTestimonials={setTestimonials}
                    sortTestimonials={sortTestimonials}
                    faqs={faqs}
                    setFaqs={setFaqs}
                    sortFaqs={sortFaqs}
                    sortCollaborators={sortCollaborators}
                    collaborators={collaborators}
                    setCollaborators={setCollaborators}
                    handleGeoChange={handleGeoChange}
                    addPushPinOnClick={addPushPinOnClick}
                    handleChangeSchedule={handleChangeSchedule}
                    handleApplySchedule={handleApplySchedule}
                    contactEmailError={contactEmailError}
                    setContactEmailError={setContactEmailError}
                    contactPhoneError={contactPhoneError}
                    setContactPhoneError={setContactPhoneError}
                  />
                )}
              {portals.public.orderMemberDesign &&
                portals.public.orderMemberDesignVersion === "v1" && (
                  <MemberV1
                    handlePortalBlocChange={handlePortalBlocChange}
                    portals={portals}
                    memberMedias={memberMedias}
                    setMemberMedias={setMemberMedias}
                    orderMemberSettings={orderMemberSettings}
                    titleAttr={titleAttr}
                    handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                    descriptionAttr={descriptionAttr}
                    handlePortalBlocDescriptionChange={
                      handlePortalBlocDescriptionChange
                    }
                    aboutImage={aboutImage}
                    setAboutImage={setAboutImage}
                    handleAboutPageBlocChange={handleAboutPageBlocChange}
                    handleAboutPageBlocTitleChange={
                      handleAboutPageBlocTitleChange
                    }
                    handleAboutPageBlocDescriptionChange={
                      handleAboutPageBlocDescriptionChange
                    }
                    valuesList={valuesList}
                    setValuesList={setValuesList}
                    valuesVideo={valuesVideo}
                    setValuesVideo={setValuesVideo}
                    galleryMedias={galleryMedias}
                    setGalleryMedias={setGalleryMedias}
                    singleServiceImage={singleServiceImage}
                    setSingleServiceImage={setSingleServiceImage}
                    sortCollaborators={sortCollaborators}
                    collaborators={collaborators}
                    setCollaborators={setCollaborators}
                    handleGeoChange={handleGeoChange}
                    addPushPinOnClick={addPushPinOnClick}
                    handleChangeSchedule={handleChangeSchedule}
                    handleApplySchedule={handleApplySchedule}
                    contactEmailError={contactEmailError}
                    setContactEmailError={setContactEmailError}
                    contactPhoneError={contactPhoneError}
                    setContactPhoneError={setContactPhoneError}
                  />
                )}
              {portals.public.customDesign && (
                <Custom
                  handlePortalBlocChange={handlePortalBlocChange}
                  portals={portals}
                  setAddMediaModalOpened={setAddMediaModalOpened}
                  medias={medias}
                  setMedias={setMedias}
                  descriptionAttr={descriptionAttr}
                  handlePortalBlocDescriptionChange={
                    handlePortalBlocDescriptionChange
                  }
                  titleAttr={titleAttr}
                  handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                  specialties={specialties}
                  folderSpecialties={folderSpecialties}
                  setFolderSpecialties={setFolderSpecialties}
                  fiduciaryConfig={fiduciaryConfig}
                  services={services}
                  setServices={setServices}
                  sortServices={sortServices}
                  sortCollaborators={sortCollaborators}
                  collaborators={collaborators}
                  setCollaborators={setCollaborators}
                  testimonials={testimonials}
                  setTestimonials={setTestimonials}
                  sortTestimonials={sortTestimonials}
                  faqs={faqs}
                  setFaqs={setFaqs}
                  sortFaqs={sortFaqs}
                  handleGeoChange={handleGeoChange}
                  addPushPinOnClick={addPushPinOnClick}
                  handleChangeSchedule={handleChangeSchedule}
                  handleApplySchedule={handleApplySchedule}
                  contactEmailError={contactEmailError}
                  setContactEmailError={setContactEmailError}
                  contactPhoneError={contactPhoneError}
                  setContactPhoneError={setContactPhoneError}
                />
              )}
              {!portals.public.customDesign &&
                !portals.public.orderMemberDesign && (
                  <Classic
                    handlePortalBlocChange={handlePortalBlocChange}
                    portals={portals}
                    setAddMediaModalOpened={setAddMediaModalOpened}
                    medias={medias}
                    setMedias={setMedias}
                    descriptionAttr={descriptionAttr}
                    handlePortalBlocDescriptionChange={
                      handlePortalBlocDescriptionChange
                    }
                    titleAttr={titleAttr}
                    handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                    sortCollaborators={sortCollaborators}
                    collaborators={collaborators}
                    setCollaborators={setCollaborators}
                    handleGeoChange={handleGeoChange}
                    addPushPinOnClick={addPushPinOnClick}
                    handleChangeSchedule={handleChangeSchedule}
                    handleApplySchedule={handleApplySchedule}
                    contactEmailError={contactEmailError}
                    setContactEmailError={setContactEmailError}
                    contactPhoneError={contactPhoneError}
                    setContactPhoneError={setContactPhoneError}
                    specialties={specialties}
                    folderSpecialties={folderSpecialties}
                    setFolderSpecialties={setFolderSpecialties}
                    fiduciaryConfig={fiduciaryConfig}
                  />
                )}
            </>
          )}
        </div>
      )}
      <AddMedia
        isOpen={addMediaModalOpened}
        close={() => {
          setAddMediaModalOpened(false);
        }}
        token={auth.token}
        community={fiduciary}
        medias={medias}
        setMedias={setMedias}
      />
      <ModalConfirm
        type="send"
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => sendRequest()}
        inProcess={isSaving}
        title={_("customization_request_confirm_title")}
        text={_("customization_request_confirm_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default Public;

import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { toast } from "react-toastify";

import _ from "../../i18n";
import {
  saveAppsSettings,
  saveFiduciaryStatus,
  saveOECCBBCollab,
} from "../../api";

import Loader from "../common/Loader";
import Button from "../common/Button";
import FormSelect from "../common/FormSelect";
import styles from "./FiduciaryModal.module.scss";
import { DAYS, NL_PLANS, MODE_Options } from "../../config";
import { setFiduciary } from "../../store";

const FiduciaryAcceptModal = ({
  modalOpen,
  setModalOpen,
  fiduciary,
  refreshList,
}) => {
  const auth = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);
  const [uaMode, setUaMode] = useState(MODE_Options[0]);
  const [newsletterPlan, setNewsletterPlan] = useState(NL_PLANS[0]);
  const [tools, setTools] = useState([
    {
      type: "BLOG_NL_CREATE",
      config: newsletterPlan.value,
      basicPlan: newsletterPlan.value,
      frequency:
        newsletterPlan.value === "PERSONALIZED" ? "BY_WEEK" : "BY_MONTH",
      selectedWeek: "",
      senders: [],
      isAutomatic: false,
      day: DAYS[0],
      hour: {
        value: "00",
        label: "00",
      },
      minute: {
        value: "00",
        label: "00",
      },
      isLogoUaVisible: true,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fiduciary) {
      if (
        fiduciary.uaMode !== "OECCBB_MEMBER" &&
        fiduciary.membershipOrder?.items?.length > 0
      ) {
        setUaMode(MODE_Options[3]);
      } else if (fiduciary.uaMode) {
        const mode = MODE_Options.filter((i) => i.value === fiduciary.uaMode);
        if (mode.length > 0) {
          setUaMode(mode[0]);
        }
      }
      const preferences = fiduciary[`newsletterPreferences`];
      if (preferences && preferences.apps) {
        const plan = preferences.apps.find(
          (item) => item.type === "BLOG_NL_CREATE"
        )?.config;
        const matchingPlan = NL_PLANS.find((p) => p.value === plan);
        setNewsletterPlan(matchingPlan ? matchingPlan : NL_PLANS[0]);
        let toolsUpdates = preferences.apps.map((tool) => {
          if (tool.type === "BLOG_NL_CREATE") {
            return {
              ...tool,
              config: plan,
              basicPlan: "",
              frequency: plan === "PERSONALIZED" ? "BY_WEEK" : "BY_MONTH",
            };
          }
          return tool;
        });
        setTools(toolsUpdates);
      }
    }
  }, [fiduciary]);

  const handleSave = async () => {
    let dataNote = fiduciary.note ? fiduciary.note : [];
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    dataNote.push({
      date: strDate,
      msg: "STATUS_VALIDATED",
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    let data = {
      id: fiduciary.id,
      uaStatus: "VALIDATED",
      uaMode: uaMode.value,
      note: JSON.stringify(dataNote),
    };

    if (fiduciary.membershipOrder?.hasFiduciaryPlan) {
      data.hasFiduciaryPlan = true;
    }

    setIsSaving(true);

    let dataPreferences = {
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        appName: "newsletter",
        tools: tools,
      },
    };
    try {
      await saveAppsSettings(dataPreferences);

      const preferences = fiduciary[`newsletterPreferences`] || {};
      dispatch(
        setFiduciary({
          ...fiduciary,
          newsletterPreferences: {
            ...preferences,
            apps: tools,
          },
        })
      );

      await saveFiduciaryStatus(auth.token, data);

      if (
        uaMode.value === "OECCBB_MEMBER" &&
        fiduciary?.allLegalRepresentative?.length > 0
      ) {
        await saveOECCBBCollab(auth.token, {
          id: fiduciary.id,
          userId: fiduciary.allLegalRepresentative[0].id,
        });
      }

      //toast.success(_("ua_status_successfully"));
      toast.success(_("successfully_saved"));
      refreshList();

      setIsSaving(false);
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving app settings:", error);
      toast.error(_("error"));
      setIsSaving(false);
    }
  };

  return (
    <Modal
      width="50vw"
      height="50vh"
      closable={false}
      open={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{_("text_ua_validate_confirm")}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close" />
      </div>

      <div className={styles.content}>
        {fiduciary?.membershipOrder?.items?.length > 0 && (
          <>
            <h3>Formules OECCBB achetés:</h3>
            <ul className={styles.list}>
              {fiduciary.membershipOrder.items.map((i) => (
                <li key={i.id}>{i.name}</li>
              ))}
            </ul>
          </>
        )}
        <div className="cell small-12 large-6 grid-x grid-margin-x">
          <div className="cell small-12 large-6">
            <FormSelect
              name="country"
              value={uaMode}
              label="Choisissez le mode à attribuer"
              handleChange={(option) => setUaMode(option)}
              options={MODE_Options}
            />
          </div>
          <div className="cell small-12 large-6">
            <FormSelect
              name="plan"
              value={newsletterPlan}
              label={_("choose_plan")}
              handleChange={(option) => {
                setNewsletterPlan(option);
                let toolsUpdates = tools.map((tool) => {
                  if (tool.type === "BLOG_NL_CREATE") {
                    return {
                      ...tool,
                      config: option.value,
                      frequency:
                        option.value === "PERSONALIZED"
                          ? "BY_WEEK"
                          : "BY_MONTH",
                    };
                  }
                  return tool;
                });
                setTools(toolsUpdates);
              }}
              options={NL_PLANS}
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader style={{ height: "10px" }} color={"#fff"} />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("validate")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default FiduciaryAcceptModal;
